import React, { useEffect, useRef } from "react";
import $ from "jquery";

const TextEditor = ({ description, setDescription }) => {
    const editorRef = useRef();


    useEffect(() => {
        $('.newPost').on('click', 'button[data-func]', function() {
            document.execCommand($(this).data('func'), false);
        });

        $('.newPost').on('change', 'select[data-func]', function() {
            var $value = $(this).find(':selected').val();
            document.execCommand($(this).data('func'), false, $value);
        });

        if (typeof Storage !== "undefined") {
            $('.editor').on('keypress', function() {
                $(this).find('.saved').detach();
            });

            $('.editor').html(localStorage.getItem("wysiwyg"));

            $('button[data-func="save"]').on('click', function() {
                var $content = $('.editor').html();
                localStorage.setItem("wysiwyg", $content);
                $('.editor').append('<span class="saved"><i class="fa fa-check"></i></span>').fadeIn(function() {
                    $(this).find('.saved').fadeOut(500);
                });
            });

            $('button[data-func="clear"]').on('click', function() {
                $('.editor').html('');
                localStorage.removeItem("wysiwyg");
            });
        }

        if (editorRef.current) {
            editorRef.current.innerHTML = description;
        }
    }, [description]);

    const handleEditorChange = () => {
        if (editorRef.current) {
            setDescription(editorRef.current.innerHTML);
        }
    };

    return (
        <div className="wrap">
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/normalize/5.0.0/normalize.min.css" />
            <link rel='stylesheet' href='https://netdna.bootstrapcdn.com/font-awesome/4.0.3/css/font-awesome.css' />
            <script src="https://cdnjs.cloudflare.com/ajax/libs/prefixfree/1.0.7/prefixfree.min.js"></script>

            <div className="newPost">
                <div className="toolbar">
                    <button type="button" data-func="bold"><i className="fa fa-bold"></i></button>
                    <button type="button" data-func="italic"><i className="fa fa-italic"></i></button>
                    <button type="button" data-func="underline"><i className="fa fa-underline"></i></button>
                    <button type="button" data-func="justifyleft"><i className="fa fa-align-left"></i></button>
                    <button type="button" data-func="justifycenter"><i className="fa fa-align-center"></i></button>
                    <button type="button" data-func="justifyright"><i className="fa fa-align-right"></i></button>
                    <button type="button" data-func="insertunorderedlist"><i className="fa fa-list-ul"></i></button>
                    <button type="button" data-func="insertorderedlist"><i className="fa fa-list-ol"></i></button>
                    <div className="customSelect">
                        <select data-func="fontname">
                            <optgroup label="Serif Fonts">
                                <option value="Bree Serif">Bree Serif</option>
                                <option value="Georgia">Georgia</option>
                                <option value="Palatino Linotype">Palatino Linotype</option>
                                <option value="Times New Roman">Times New Roman</option>
                            </optgroup>
                            <optgroup label="Sans Serif Fonts">
                                <option value="Arial">Arial</option>
                                <option value="Arial Black">Arial Black</option>
                                <option value="Asap" selected>Asap</option>
                                <option value="Comic Sans MS">Comic Sans MS</option>
                                <option value="Impact">Impact</option>
                                <option value="Lucida Sans Unicode">Lucida Sans Unicode</option>
                                <option value="Tahoma">Tahoma</option>
                                <option value="Trebuchet MS">Trebuchet MS</option>
                                <option value="Verdana">Verdana</option>
                            </optgroup>
                            <optgroup label="Monospace Fonts">
                                <option value="Courier New">Courier New</option>
                                <option value="Lucida Console">Lucida Console</option>
                            </optgroup>
                        </select>
                    </div>
                    <div className="customSelect">
                        <select data-func="formatblock">
                            <option value="h1">Heading 1</option>
                            <option value="h2">Heading 2</option>
                            <option value="h4">Subtitle</option>
                            <option value="p" selected>Paragraph</option>
                        </select>
                    </div>
                </div>
                <div className="editor" contentEditable onBlur={handleEditorChange} ref={editorRef}></div>
            </div>

            <script src='https://cdnjs.cloudflare.com/ajax/libs/jquery/2.1.3/jquery.min.js'></script>
        </div>
    );
};

export default TextEditor;
