import React from "react";
import { useNavigate } from 'react-router-dom';
import { useState } from "react";
import mapMarkerIcon from "../images/map-marker-icon.png";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

function useQuery() {
    return new URLSearchParams(window.location.search);
}

function EventPage() {
    const query = useQuery();
    const id = query.get('id');
    const sessionId =  localStorage.getItem("sessionId");
    const navigate = useNavigate();
    const [eventId, setEventId] = useState(id);
    const [eventData, setEventData] = useState([]);
    const [error, setError] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [hasJoined, setHasJoined] = useState(false);
    const [UserData, setUserData] = useState(null);
    const [events, setEvents] = useState([]);
    const [joinedEvents, setJoinedEvents] = useState([]);
    const [noEvent, setNoEvent] = useState(false);
    const [noJoinedEvent, setNoJoinedEvent] = useState(false);

    const customIcon = L.icon({
        iconUrl: mapMarkerIcon,
        iconSize: [25, 40],
        iconAnchor: [10, 40],
        popupAnchor: [-3, -76],
    });

    React.useEffect(() => {
        fetch("api/auth/load-event", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ eventId, sessionId }), // send eventId along with sessionId
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                setIsLoading(false);
                setEventData(Array.isArray(data.event) ? data.event : [data.event]);
                if (data.loggedin === true) {
                    setIsLoggedIn(true);
                }
                if (data.UserData != null) {
                    setHasJoined(true);
                    setUserData(data.UserData);
                }
                else {
                    setHasJoined(false);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
                setIsLoading(false);
                setError("Failed to load event");
            });

    }, [id, navigate]);

    function onLeaveEventClick(event) {
        setIsLoading(true);
        const sessionId = localStorage.getItem("sessionId");
        const eventId = event.target.id; // get the button's ID

        fetch("api/auth/leave-event", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ sessionId, eventId }), // send eventId along with sessionId
        })
            .then((response) => response.json())
            .then((data) => {
                if (Array.isArray(data.events)) {
                    setEvents(data.events);
                } else {
                    console.error("data.events is not an array:", data.events);
                    setNoEvent(true);
                }
                if (Array.isArray(data.joined_events)) {
                    setJoinedEvents(data.joined_events);
                } else {
                    console.error("data.joined_events is not an array:", data.joined_events);
                    setNoJoinedEvent(true);
                }
                setIsLoading(false);
                window.location.reload();
            })
            .catch((error) => {
                console.error("Error:", error);
                setIsLoading(false);
            });
    }

    function onJoinEventClick(event) {
        setIsLoading(true);
        const sessionId = localStorage.getItem("sessionId");
        const eventId = event.target.id; // get the button's ID

        fetch("api/auth/join-event", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ sessionId, eventId }), // send eventId along with sessionId
        })
            .then((response) => response.json())
            .then((data) => {
                if (Array.isArray(data.events)) {
                    setEvents(data.events);
                } else {
                    console.error("data.events is not an array:", data.events);
                    setNoEvent(true);
                }
                if (Array.isArray(data.joined_events)) {
                    setJoinedEvents(data.joined_events);
                } else {
                    console.error("data.joined_events is not an array:", data.joined_events);
                    setNoJoinedEvent(true);
                }
                setIsLoading(false);
                window.location.reload();
            })
            .catch((error) => {
                console.error("Error:", error);
                setIsLoading(false);
            });
    }

    return (
        <>
            <a href='/dashboard' ><button style={{ marginTop: "20px", fontSize: "12px" }}>Back to Home</button></a>
            {eventData.map((event) => (
                <div className="event-data" key={event.id}>
                    <h1>{event.name}</h1>
                    <h2>{event.date}</h2>
                    <p>Entries Close: {event.entries_close}</p>
                    <p>Max Entries: {event.max_entries} </p>
                    {hasJoined && <p>Current Category: {UserData}</p>}
                    {isLoggedIn ? (
                        <>
                            {hasJoined ? (
                                <button onClick={onLeaveEventClick} id={event.id}>Leave Event</button>
                            ) : (
                                <button onClick={onJoinEventClick} id={event.id}>Join Event</button>
                            )}
                        </>
                    ) : (
                        <a href="/login"><button>Login to join</button></a>
                    )}
                    <p>{event.short_description}</p>
                    <p>{event.description}</p>
                    <a
                        href={`https://www.google.com/maps/search/?api=1&query=${event.location}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <button>Get Directions</button>
                    </a>
                    <MapContainer
                        center={event.location.split(',')}
                        zoom={15}
                        style={{ height: "400px", width: "100%" }}

                    >
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <Marker
                            position={event.location.split(',')}
                            icon={customIcon}
                        ></Marker>
                    </MapContainer>
                </div>
            ))}
            {error && <div className="error">{error}</div>}
            {isLoading && <div className="overlay"></div>}
            {isLoading && <div className="spin"></div>}
        </>
    );
}

export default EventPage;