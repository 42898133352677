import React, { useState, useEffect } from 'react';

function PasswordForm({ onSubmitPassword, errorMessage }) {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');

  const requirements = [
    { id: 1, text: "An uppercase letter", test: (password) => /[A-Z]/.test(password) },
    { id: 2, text: "A number", test: (password) => /\d/.test(password) },
    { id: 3, text: "A special character (@ $ ! % * # ? & £ - )", test: (password) => /[@$!%*#?&£-]/.test(password) },
    { id: 4, text: "At least 12 characters", test: (password) => password.length >= 12 },
  ];

  const handleSubmit = (event) => {
    event.preventDefault();
    if (requirements.some(req => !req.test(password))) {
      setError('Password does not meet strength requirements.');
      return;
    }
    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }
    setError(''); // Clear any previous error
    onSubmitPassword(password);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <input placeholder='Password' type="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
      </div>
      <div>
        <input type="password" placeholder='Confirm Password' id="confirmPassword" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required />
        {error && <div style={{ color: 'red' }}>{error}</div>}
        {errorMessage && <div style={{ color: 'red', marginLeft: '10px' }}>{errorMessage}</div>}
        <ul style={{padding: "3px"}} >
          {requirements.map(req => (
            <li key={req.id} style={{ listStyle: "none",color: req.test(password) ? 'green' : 'red' }}>{req.test(password) ? '✓' : '✗'} {req.text}</li>
          ))}
        </ul>
      </div>
      <button type="submit">Set Password</button>
    </form>
  );
}

export default PasswordForm;