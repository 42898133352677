import React from "react";

const handleLogout = () => {
  localStorage.removeItem("sessionId");
  window.location.href = "/login";
};

function LeftNavBar() {
  const [notChild, setNotChild] = React.useState(false);

    React.useEffect(() => {
        const sessionId = localStorage.getItem("sessionId");
        fetch("api/auth/is-child", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ sessionId }),
        }).then((response, data) => {
            console.log(response);
            if (response.ok) {
                setNotChild(false);
            } else {
                setNotChild(true);
            }
        });
    }, []);

  function handleClick() {
    document
      .querySelector(".left-nav-bar")
      .classList.toggle("left-nav-bar--active");
    document
      .querySelector(".menu-btn__burger")
      .classList.toggle("menu-btn__burger--active");
  }

  return (
    <>
      <div className="menu-btn">
        <div className="menu-btn__burger" onClick={handleClick}>
          <div className="menu-btn__burger__patty"></div>
          <div className="menu-btn__burger__patty"></div>
          <div className="menu-btn__burger__patty"></div>
        </div>
      </div>
      <nav className="left-nav-bar">
        <ul>
          <li>
            <a href="/dashboard">Home</a>
          </li>
          <li>
            <a href="/account">Account Details</a>
          </li>
          <li>
            <a href="/wallet-pass">Membership Card</a>
          </li>
          {notChild && (
            <li>
              <a href="/childaccountsettings">Child Accounts</a>
            </li>
          )}
          <li className="logout-btn" onClick={handleLogout}>
            Logout
          </li>
        </ul>
      </nav>
    </>
  );
}

export default LeftNavBar;
