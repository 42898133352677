import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import mapMarkerIcon from "../images/map-marker-icon.png";
import NavBar from "../components/NavBar";

function AdminPage() {
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [events, setEvents] = useState([]);
    const [noEvent, setNoEvent] = useState(false);
    const [details, setDetails] = useState("");
    const [draftEvents, setdraftEvents] = useState([]);
    const [noDraftEvents, setnoDraftEvents] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showEvent, setShowEvent] = useState(false);
    const [viewEventData, setViewEvent] = useState({});
    const [eventLocation, setEventLocation] = useState({ lat: 54.954155, lng: -4.419234 });
    const [viewParticipants, setViewParticipants] = useState(false);
    const [users, setUsers] = useState([]);

    const customIcon = L.icon({
        iconUrl: mapMarkerIcon,
        iconSize: [25, 40],
        iconAnchor: [10, 40],
        popupAnchor: [-3, -76],
    });

    function onViewParticipants(event) {
        setIsLoading(true);
        fetch("api/auth/load-participants", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ sessionId: localStorage.getItem("sessionId"), eventId: event.target.id }),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                setUsers(data.users);
                setViewParticipants(true)
                setIsLoading(false);
            })
            .catch((error) => {
                console.error("Error:", error);
                setIsLoading(false);
            });
    }

    function onViewEvent(event) {
        setIsLoading(true);
        const sessionId = localStorage.getItem("sessionId");
        const eventId = event.target.id; // get the button's ID

        fetch("api/auth/load-event", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ sessionId, eventId }), // send eventId along with sessionId
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                if (data) {
                    setViewEvent(data);
                    setShowEvent(true);
                }
                setIsLoading(false);
            })
            .catch((error) => {
                console.error("Error:", error);
                setIsLoading(false);
            });
    }

    useEffect(() => {
        document.title = "Admin Page";
        setIsLoading(true);
        const sessionId = localStorage.getItem("sessionId");

        fetch("api/auth/load-admin-dashboard", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ sessionId }),
        })
            .then((response) => response.json())
            .then((data) => {
                setDetails(data.user_name);
                if (Array.isArray(data.events)) {
                    setEvents(data.events);
                } else {
                    console.error("data.events is not an array:", data.events);
                    setNoEvent(true);
                }
                console.log(data);
            })
            .catch((error) => {
                console.error("Error:", error);
            })
            .finally(() => {
                setIsLoading(false);
            });
        fetch("api/auth/load-draft-events", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ sessionId }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (Array.isArray(data.events)) {
                    setdraftEvents(data.events);
                } else {
                    console.error("data.draft_events is not an array:", data.events);
                    setnoDraftEvents(true);
                }
                console.log(data);
            })
            .catch((error) => {
                console.error("Error:", error);
            })
            .finally(() => {
                setIsLoading(false);
            });

        function onViewEvent(event) {
            setIsLoading(true);
            const sessionId = localStorage.getItem("sessionId");
            const eventId = event.target.id; // get the button's ID

            fetch("api/auth/load-event", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ sessionId, eventId }), // send eventId along with sessionId
            })
                .then((response) => response.json())
                .then((data) => {
                    console.log(data);
                    if (data) {
                        setViewEvent(data);
                        setShowEvent(true);
                    }
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.error("Error:", error);
                    setIsLoading(false);
                });
        }


        const fetchData = async () => {
            await fetch('api/auth/is-admin', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ "sessionId": localStorage.getItem('sessionId') }),
            })
                .then(response => {
                    if (response.status === 200) {
                        console.log('Success');
                        return response.json();
                    } else {
                        console.log('Error');
                        navigate('/dashboard');
                        throw new Error('Failed to fetch admin data');
                    }
                })
                .then(data => {
                    console.log(data);
                    setName(data.name);
                })
                .catch(error => {
                    console.error(error);
                });
        };
        fetchData();
    }, []);

    function onEditEvent(e) {
        console.log(e.target.id);
        navigate('/edit-event?eventId=' + e.target.id);
    }

    function onEditDraft(e) {
        console.log(e.target.id);
        navigate('/edit-event?eventId=' + e.target.id);
    }

    return (
        <div>
            <button style={{marginTop: "10px"}} ><a href='/dashboard' >Back to Home</a></button>
            {viewParticipants && <div className="overlay"></div>}
            {viewParticipants && <div><h1 className='z-index' >Participants</h1>
                <button className='z-index' onClick={() => setViewParticipants(false)}>Close</button></div>}
            {viewParticipants && <div className='participants-list'>
                {users.map((user, index) => (
                    <div className='participants' key={index}>
                        <p>Email: {user.email}</p>
                        <p>First Name: {user.first_name}</p>
                        <p>Surname: {user.surname}</p>
                    </div>
                ))}
            </div>}

            {showEvent && <>

                <div className="eventView">
                    <h1>{viewEventData.event.name}</h1>
                    <div className="evn-view-info" >
                        <p>Date/Time: {viewEventData.event.time}</p>
                        <p>Max Entries: {viewEventData.event.max_entries}</p>
                        <p>Entries Close: {viewEventData.event.entries_close}</p>
                    </div>
                    <p>Categroies: <br></br> {viewEventData.event.categories}</p>
                    <p>{viewEventData.event.short_description}</p>
                    <p dangerouslySetInnerHTML={{ __html: viewEventData.event.description }}></p>
                    <MapContainer
                        center={[eventLocation.lat, eventLocation.lng]}
                        zoom={10}
                        style={{ height: "40vh", width: "92%" }}
                    >
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        />
                        <Marker position={[eventLocation.lat, eventLocation.lng]} icon={customIcon}></Marker>
                    </MapContainer>
                    <button onClick={() => setShowEvent(false)}>Close</button>
                </div>
                <div className="overlay"></div>

            </>}
            <header className="admin-h">
                <h1 className="adh1">Hello {name}</h1>
                <h2 className="adh1">What would you like to do today?</h2>
            </header>
            <div className="boxca">
                <a href='/create-event' className="boxa">
                    <h3>Create Event</h3>
                </a>
                <a className="boxa" href='/manage-member'>
                    <h3>Manage Members</h3>
                </a>
            </div>
            <div>
                <div className="uje">
                    <div className="eventbar" id="drafts">
                        <h2>Drafts</h2>
                        {noDraftEvents && <p>You have not joined any events</p>}
                        {draftEvents.map((draft_events) => (
                            <div class="event">
                                <h3>{draft_events.name}</h3>
                                <p id="Time">Time: {draft_events.time}</p>
                                <p id="location">Location: {draft_events.location}</p>
                                <p id="shortDescription">{draft_events.short_description}</p>
                                <button onClick={onViewEvent} id={draft_events.id}>View Event</button>
                                <button onClick={onEditDraft} id={draft_events.id}>Continue Editing</button>
                            </div>
                        ))}
                    </div>
                    <div className="eventbar" id="upcoming">
                        <h2>Current Events</h2>
                        {noEvent && <p>No Upcoming Events</p>}
                        {events.map((event) => (
                            <div class="event">
                                <h3>{event.name}</h3>
                                <p id="Time">Time: {event.time}</p>
                                <p>No of Entries:</p>
                                <p id="location">Location: {event.location}</p>
                                <p id="shortDescription">{event.short_description}</p>
                                <button onClick={onViewEvent} id={event.id}>View Event</button>
                                <button onClick={onEditEvent} id={event.id}>
                                    Edit Event
                                </button>
                                <button onClick={onViewParticipants} id={event.id}>View Participants</button>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <NavBar />
            {isLoading && <div className="overlay"></div>}
            {isLoading && <div className="spin"></div>}
        </div>
    );
}

export default AdminPage;