import React, { useState, useEffect } from "react";
import LeftNavBar from "../components/LeftNavbar";
import ValidateSession from "../components/ValidateSession";
import NavBar from "../components/NavBar";

function AccountDetails() {
  const validateSession = ValidateSession();
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [changingPass, setChangingPass] = useState(false);
  const [details, setDetails] = useState({
    email: "",
    firstName: "",
    surname: "",
    phone: "",
    dob: "",
    addressLine1: "",
    addressLine2: "",
    townCity: "",
    postcode: "",
    emergancyContact: "",
    emergancyContactPhone: "",
    emergancyContactEmail: "",
    relationship: "",
    medicalConditions: "",
  });

  useEffect(() => {
    setIsLoading(true);
    const sessionId = localStorage.getItem("sessionId");
    fetch("api/auth/request-all-details", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ sessionId }),
    })
      .then((response) => response.json())
      .then((data) => {
        setDetails(data.user);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const handleSaveClick = () => {
    setIsLoading(true);
    const sessionId = localStorage.getItem("sessionId");
    fetch("api/auth/update-details", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ sessionId, details }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        setIsEditing(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleChange = (event, name) => {
    setDetails((prevDetails) => {
      let newDetails = { ...prevDetails };
      if (name === "dob") {
        let dobParts = newDetails.dob.split("-");
        if (event.target.name === "day") dobParts[0] = event.target.value;
        if (event.target.name === "month") dobParts[1] = event.target.value;
        if (event.target.name === "year") dobParts[2] = event.target.value;
        newDetails.dob = dobParts.join("-");
      } else {
        newDetails[event.target.name] = event.target.value;
      }
      return newDetails;
    });
  };

  const handleChangingPass = () => {
    setChangingPass(!changingPass);
  };

  const handleSavePass = () => {
    setIsLoading(true);
    const sessionId = localStorage.getItem("sessionId");
    const oldPassword = document.getElementsByName("oldPassword")[0].value;
    const newPassword = document.getElementsByName("newPassword")[0].value;
    const confirmPassword =
      document.getElementsByName("confirmPassword")[0].value;
    if (newPassword === confirmPassword) {
      fetch("api/auth/change-password", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ sessionId, oldPassword, newPassword }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(response.message);
          }
          return response.json();
        })
        .then((data) => {
          console.log(data);
          setChangingPass(false);
        })
        .catch((error) => {
          console.error("Error:", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      alert("Passwords do not match");
    }
  };

  return (
    <div className="main-content">
      <div>
        {isLoading && <div className="overlay"></div>}
        {isLoading && <div className="spin"></div>}
      </div>
      <LeftNavBar />
      <h1>Account Details</h1>
      <div className="account-details">
        <div className="account-details-left">
          <h2>Personal Information</h2>
          <p>
            Email:{" "}
            {isEditing ? (
              <input
                type="text"
                name="email"
                defaultValue={details.email}
                onChange={handleChange}
              />
            ) : (
              <span>{details.email}</span>
            )}
          </p>
          <p>
            First Name:{" "}
            {isEditing ? (
              <input
                type="text"
                name="firstName"
                defaultValue={details.firstName}
                onChange={handleChange}
              />
            ) : (
              <span>{details.firstName}</span>
            )}
          </p>
          <p>
            Surname:{" "}
            {isEditing ? (
              <input
                type="text"
                name="surname"
                defaultValue={details.surname}
                onChange={handleChange}
              />
            ) : (
              <span>{details.surname}</span>
            )}
          </p>
          <p>
            Phone:{" "}
            {isEditing ? (
              <input
                type="text"
                name="phone"
                defaultValue={details.phone}
                onChange={handleChange}
              />
            ) : (
              <span>{details.phone}</span>
            )}
          </p>
          <p>
            Date of birth:
            {isEditing ? (
              <div>
                <input
                  type="text"
                  name="day"
                  defaultValue={details.dob.split("-")[0]}
                  onChange={(e) => handleChange(e, "dob")}
                  placeholder="DD"
                  maxLength="2"
                  size="2"
                />
                <input
                  type="text"
                  name="month"
                  defaultValue={details.dob.split("-")[1]}
                  onChange={(e) => handleChange(e, "dob")}
                  placeholder="MM"
                  maxLength="2"
                  size="2"
                />
                <input
                  type="text"
                  name="year"
                  defaultValue={details.dob.split("-")[2]}
                  onChange={(e) => handleChange(e, "dob")}
                  placeholder="YYYY"
                  maxLength="4"
                  size="4"
                />
              </div>
            ) : (
              <span>{details.dob}</span>
            )}
          </p>
          <p>
            Address Line 1:{" "}
            {isEditing ? (
              <input
                type="text"
                name="addressLine1"
                defaultValue={details.addressLine1}
                onChange={handleChange}
              />
            ) : (
              <span>{details.addressLine1}</span>
            )}
          </p>
          <p>
            Address Line 2:{" "}
            {isEditing ? (
              <input
                type="text"
                name="addressLine2"
                defaultValue={details.addressLine2}
                onChange={handleChange}
              />
            ) : (
              <span>{details.addressLine2}</span>
            )}
          </p>
          <p>
            Town/City:{" "}
            {isEditing ? (
              <input
                type="text"
                name="townCity"
                defaultValue={details.townCity}
                onChange={handleChange}
              />
            ) : (
              <span>{details.townCity}</span>
            )}
          </p>
          <p>
            Postcode:{" "}
            {isEditing ? (
              <input
                type="text"
                name="postcode"
                defaultValue={details.postcode}
                onChange={handleChange}
              />
            ) : (
              <span>{details.postcode}</span>
            )}
          </p>
          <p>
            Emergency Contact Name:{" "}
            {isEditing ? (
              <input
                type="text"
                name="emergancyContact"
                defaultValue={details.emergancyContact}
                onChange={handleChange}
              />
            ) : (
              <span>{details.emergancyContact}</span>
            )}
          </p>
          <p>
            Emergency Contact Phone:{" "}
            {isEditing ? (
              <input
                type="text"
                name="emergancyContactPhone"
                defaultValue={details.emergancyContactPhone}
                onChange={handleChange}
              />
            ) : (
              <span>{details.emergancyContactPhone}</span>
            )}
          </p>
          <p>
            Emergency Contact Email:{" "}
            {isEditing ? (
              <input
                type="text"
                name="emergancyContactEmail"
                defaultValue={details.emergancyContactEmail}
                onChange={handleChange}
              />
            ) : (
              <span>{details.emergancyContactEmail}</span>
            )}
          </p>
          <p>
            Relationship:{" "}
            {isEditing ? (
              <input
                type="text"
                name="relationship"
                defaultValue={details.relationship}
                onChange={handleChange}
              />
            ) : (
              <span>{details.relationship}</span>
            )}
          </p>
          <p>
            Medical Conditions:{" "}
            {isEditing ? (
              <input
                type="text"
                name="medicalConditions"
                defaultValue={details.medicalConditions}
                onChange={handleChange}
              />
            ) : (
              <span>{details.medicalConditions}</span>
            )}
          </p>
          {changingPass && (
            <div>
              <p>
                Old Password: <input type="password" name="oldPassword" />
              </p>
              <p>
                New Password: <input type="password" name="newPassword" />
              </p>
              <p>
                Confirm Password:{" "}
                <input type="password" name="confirmPassword" />
              </p>
            </div>
          )}

          <button
            id="changePasswordButton"
            onClick={changingPass ? handleSavePass : handleChangingPass}
          >
            {changingPass ? "Save Password" : "Change Password"}
          </button>
          <button
            id="editDetailsButton"
            onClick={isEditing ? handleSaveClick : handleEditClick}
          >
            {isEditing ? "Save Details" : "Edit Details"}
          </button>
        </div>
      </div>
      <NavBar />
    </div>
  );
}

export default AccountDetails;
