import React from "react";
import { useState, useRef } from "react";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import mapMarkerIcon from "../images/map-marker-icon.png";
import L from "leaflet";
import TextEditor from "./TextEditor";

function CreateEventForm({ onSubmitData }) {
    const [eventName, setEventName] = useState("");
    const [categories, setCategories] = useState([]);
    const [entriesClose, setEntriesClose] = useState("");
    const [entriesMax, setEntriesMax] = useState("");
    const [eventTime, setEventTime] = useState("");
    const [shortDescription, setShortDescription] = useState("");
    const [description, setDescription] = useState("");
    const [location, setLocation] = useState("");
    const [eventLocation, setEventLocation] = useState({ lat: 54.954155, lng: -4.419234 });
    const sessionId = localStorage.getItem("sessionId");
    const [errors, setErrors] = useState({});
    const dayInputRef = useRef(null);
    const monthInputRef = useRef(null);
    const yearInputRef = useRef(null);
    const [time_time, set_time_time] = useState('');
    const [time_day, set_time_day] = useState('');
    const [time_month, set_time_month] = useState('');
    const [time_year, set_time_year] = useState('');
    const [close_day, set_close_day] = useState('');
    const [close_month, set_close_month] = useState('');
    const [close_year, set_close_year] = useState('');
    const closeMonthInputRef = useRef(null);
    const closeYearInputRef = useRef(null);

    if (location === "") {
        setLocation("54.954155, -4.419234");
    }

    const validateForm = () => {
        let isValid = true;
        let errors = {};

        if (eventName === "") {
            isValid = false;
            errors.eventName = "Please enter an event name";
        }
        if (categories.length === 0) {
            isValid = false;
            errors.categories = "Please enter at least one category";
        }
        if (entriesClose === "") {
            isValid = false;
            errors.entriesClose = "Please enter a date";
        }
        if (entriesMax === "") {
            isValid = false;
            errors.entriesMax = "Please enter a number";
        }
        if (eventTime === "") {
            isValid = false;
            errors.eventTime = "Please enter a date";
        }
        if (shortDescription === "") {
            isValid = false;
            errors.shortDescription = "Please enter a short description";
        }
        if (description === "") {
            isValid = false;
            errors.description = "Please enter a description";
        }
        setErrors(errors);
        return isValid;
    };

    function handleSubmit(event) {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        event.preventDefault();
        const eventTime = `${time_time} ${time_day}-${time_month}-${time_year}`;
        const entriesClose = `${close_day}-${close_month}-${close_year}`;

        setEntriesClose(entriesClose);
        setEventTime(eventTime);
        if (!validateForm()) {
            console.log("Form not submitted");
            return;
        }
        console.log("Form submitted");
        onSubmitData({
            eventName,
            formattedCategories,
            entriesClose,
            entriesMax,
            eventTime,
            shortDescription,
            description,
            location,
            sessionId,
            isDraft: false,
        });
    }


    let formattedCategories = categories.join(", ");

    function handleSaveDraft(event) {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;

        if (eventName === "") {
            let errors = {};
            errors.eventName = "Please enter an event name";
            setErrors(errors);
            return;
        }
        event.preventDefault();
        console.log("Form submitted");
        onSubmitData({
            eventName,
            formattedCategories,
            entriesClose,
            entriesMax,
            eventTime,
            shortDescription,
            description,
            location,
            sessionId,
            isDraft: true,
        });
    }

    const customIcon = L.icon({
        iconUrl: mapMarkerIcon,
        iconSize: [25, 40],
        iconAnchor: [10, 40],
        popupAnchor: [-3, -76],
    });

    const updateEventLocation = (event) => {
        const value = event.target.value;
        const [lat, lng] = value.split(",").map(Number);

        if (!isNaN(lat) && !isNaN(lng)) {
            setEventLocation({ lat, lng });
        }
    };

    const handleEditorChange = (content) => {
        setDescription(content);
    };
    const handleAddCategory = () => {
        setCategories([...categories, ""]);
    };

    const handleRemoveCategory = (index) => {
        setCategories(categories.filter((_, i) => i !== index));
    };

    const handleTimeChange = (e) => {
        let value = e.target.value;
        if (value.length === 5) {
            const [hours, minutes] = value.split(':').map(Number);
            if (hours > 23 || minutes > 59) {
                value = '23:59';
            }
            dayInputRef.current.focus();
        }
        set_time_time(value ? value.toString() : '');
    };
    const handleDayChange = (e) => {
        const value = Math.max(0, Math.min(31, Number(e.target.value)));
        if (e.target.value.length === 2) {
            monthInputRef.current.focus();
        }
        set_time_day(value ? value.toString() : '');
        
    };

    const handleMonthChange = (e) => {
        const value = Math.max(0, Math.min(12, Number(e.target.value)));
        if (e.target.value.length === 2) {
            yearInputRef.current.focus();
        }
        set_time_month(value ? value.toString() : '');
    };

    const handleYearChange = (e) => {
        const value = Math.max(0, Math.min(2028, Number(e.target.value)));
        set_time_year(value ? value.toString() : '');
    };

    const handleCloseDayChange = (e) => {
        const value = Math.max(0, Math.min(31, Number(e.target.value)));
        if (e.target.value.length === 2) {
            closeMonthInputRef.current.focus();
        }
        set_close_day(value ? value.toString() : '');
    };

    const handleCloseMonthChange = (e) => {
        const value = Math.max(0, Math.min(12, Number(e.target.value)));
        if (e.target.value.length === 2) {
            closeYearInputRef.current.focus();
        }
        set_close_month(value ? value.toString() : '');
    };

    const handleCloseYearChange = (e) => {
        const value = Math.max(0, Math.min(2028, Number(e.target.value)));
        set_close_year(value ? value.toString() : '');
    };

    const handleInputChange = (event, index) => {
        const value = event.target.value;
        const name = event.target.name;

        const eventTime = `${time_time} ${time_day}-${time_month}-${time_year}`;
        const entriesClose = `${close_day}-${close_month}-${close_year}`;
        setEntriesClose(entriesClose);
        setEventTime(eventTime);

        if (event.target.name.startsWith("category")) {
            const newCategories = [...categories];
            newCategories[index] = event.target.value;
            setCategories(newCategories);
        }
        switch (name) {
            case "eventName":
                setEventName(value);
                break;
            case "categories":
                setCategories(value);
                break;
            case "entriesMax":
                setEntriesMax(value);
                break;
            case "shortDescription":
                setShortDescription(value);
                break;
            case "description":
                setDescription(value);
                break;
            case "location":
                setLocation(value);
                break;

            default:
                break;
        }
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", padding: "0" }}>
            <h1 class="adh1">Create Event</h1>
            <div className="create-event-page" style={{ display: "flex" }}>
                <div className="create-event-form">
                    <h3 style={{ fontSize: "22px", marginLeft: "15px" }}>Enter Event Details</h3>
                    <form className="create-event" action="#" method="post">
                        <label htmlFor="eventName">Event name:</label>
                        <input
                            onChange={handleInputChange}
                            type="text"
                            placeholder="e.g Duathlon"
                            id="eventName"
                            name="eventName"
                        />
                        {errors.eventName && <div style={{ color: "red" }}>{errors.eventName}</div>}

                        <label>Categories:</label>
                        {categories.map((category, index) => (
                            <div key={index}>
                                <input
                                    type="text"
                                    placeholder="e.g 12-15"
                                    name={`category${index}`}
                                    value={category}
                                    onChange={(event) => {
                                        handleInputChange(event, index);
                                    }}
                                />
                                <button
                                    class="ar-map-button remove"
                                    id="remove-categorie"
                                    style={{
                                        marginLeft: "10px",
                                        backgroundColor: "#1d1d1d",
                                        border: "1px solid rgb(164, 0, 0)",
                                    }}
                                    type="button"
                                    onClick={handleRemoveCategory.bind(null, index)}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20px"
                                        height="20px"
                                        viewBox="0 0 448 512"
                                    >
                                        <path
                                            fill="rgb(164, 0, 0)"
                                            d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z"
                                        />
                                    </svg>
                                </button>
                            </div>
                        ))}

                        <button type="button" class="ar-map-button add" id="add-categorie" onClick={handleAddCategory}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 448 512">
                                <path
                                    fill="green"
                                    d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"
                                />
                            </svg>
                        </button>
                        {errors.categories && <div style={{ color: "red" }}>{errors.categories}</div>}

                        <label htmlFor="entriesClose">Entries Close:</label>
                        <div className='date-ad'>
                            <input type="text" id="" placeholder='dd' value={close_day} onChange={handleCloseDayChange} />
                            <input type="text" id="" placeholder='mm' value={close_month} onChange={handleCloseMonthChange} ref={closeMonthInputRef} />
                            <input type="text" id="" placeholder='yyyy' value={close_year} onChange={handleCloseYearChange} ref={closeYearInputRef} />
                        </div>

                        {errors.entriesClose && <div style={{ color: "red" }}>{errors.entriesClose}</div>}

                        <label htmlFor="entriesMax">Max Entries:</label>
                        <input
                            onChange={handleInputChange}
                            type="number"
                            maxLength="5"
                            placeholder="leave 0 for Uncapped"
                            id="entriesMax"
                            name="entriesMax"
                        />
                        {errors.entriesMax && <div style={{ color: "red" }}>{errors.entriesMax}</div>}

                        <label htmlFor="eventTime">Date/Time:</label>
                        <div className='date-ad'>
                            <input type="text" id="time" placeholder='00:00' value={time_time} onChange={handleTimeChange} />
                            <input type="text" id="day" placeholder='dd' value={time_day} onChange={handleDayChange} ref={dayInputRef}/>
                            <input type="text" id="month" placeholder='mm' value={time_month} onChange={handleMonthChange} ref={monthInputRef} />
                            <input type="text" id="year" placeholder='yyyy' value={time_year} onChange={handleYearChange} ref={yearInputRef} />
                        </div>

                        {errors.eventTime && <div style={{ color: "red" }}>{errors.eventTime}</div>}

                        <label htmlFor="shortDescription">Short Description:</label>
                        <textarea
                            onChange={handleInputChange}
                            id="shortDescription"
                            name="shortDescription"
                            maxLength={300}
                            rows="4"
                        ></textarea>
                        {errors.shortDescription && <div style={{ color: "red" }}>{errors.shortDescription}</div>}

                        <label htmlFor="description">Description:</label>
                        <TextEditor onEditorChange={handleEditorChange} description={description} setDescription={setDescription} />
                        {errors.description && <div style={{ color: "red" }}>{errors.description}</div>}

                        <label htmlFor="location">Location:</label>
                        <input
                            onChange={updateEventLocation}
                            id="location"
                            placeholder="e.g 54.954155, -4.419234"
                            name="location"
                            rows="4"
                        />
                        {errors.location && <div style={{ color: "red" }}>{errors.location}</div>}

                        <button type="button" onClick={handleSaveDraft}>
                            Save Draft
                        </button>
                        <button type="submit" onClick={handleSubmit}>
                            Publish
                        </button>
                    </form>
                </div>

                <div className="preview">
                    <h3 style={{ fontSize: "22px", marginLeft: "15px" }}>Preview</h3>
                    <div className="event-preview">
                        <h1>{eventName}</h1>
                        <h3>Categories</h3>
                        <ul>
                            {categories.map((category, index) => (
                                <li key={index}>{category}</li>
                            ))}
                        </ul>
                        <h3>Entries Close</h3>
                        <p>{entriesClose}</p>
                        <h3>Max Entries: {entriesMax}</h3>
                        <h3>Event Time: {eventTime}</h3>
                        <h3>Description:</h3>
                        <p>{shortDescription}</p>
                        <p dangerouslySetInnerHTML={{ __html: description }}></p>
                        <h3>Location</h3>
                        <MapContainer
                            center={[eventLocation.lat, eventLocation.lng]}
                            zoom={10}
                            style={{ height: "40vh", width: "100%" }}
                        >
                            <TileLayer
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            />
                            <Marker position={[eventLocation.lat, eventLocation.lng]} icon={customIcon}></Marker>
                        </MapContainer>
                        <button
                            style={{ marginTop: "10px" }}
                            onClick={() =>
                                window.open(
                                    `https://www.google.com/maps/search/?api=1&query=${eventLocation.lat},${eventLocation.lng}`
                                )
                            }
                        >
                            Open in Google Maps
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreateEventForm;
