import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import DefaultPage from "./pages/DefaultPage";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import AddressPage from "./pages/AddressPage";
import PasswordPage from "./pages/PasswordPage";
import VerificationPage from "./pages/VerificationPage";
import PaymentPage from "./pages/PaymentPage";
import Dashboard from "./pages/Dashboard";
import AccountPage from "./pages/AccountPage";
import ParentLinkPage from "./pages/ParentLinkPage";
import ParentLinkConfirmation from "./pages/ParentLinkConfirm";
import CreateEventForm from "./pages/CreateEventPage";
import ClubInfoPage from "./pages/ClubInfoPage";
import EmailVerify from "./pages/EmailVerify";
import AdminPage from "./pages/AdminPage";
import ChildAccountMannage from "./pages/ChildAccountMannage";
import AddNewChild from "./pages/AddNewChild";
import EditEvent from "./pages/EditEventPage";
import ManageMember from "./pages/MannageMember";
import PasswordReset from "./pages/ResetPassword";
import EnterNewPassword from "./pages/EnterNewPassword";
import AddEmergancyContact from "./pages/AddEmergancyContact"
import EventPage from "./pages/EventPage";
import NotFound from "./pages/NotFound";
import EmergencyContactVerify from "./pages/EmergencyContactVerify";
import AddPassword from "./pages/SetPassword";
import WalletPass from "./pages/CardPage";
import { Suspense } from 'react';
import ScanQRPage from "./pages/ScanQRPage";
import "./css/App.css";



function App() { 

    return (
        <Suspense >
            <Router>
                <Routes>
                    <Route path="/" element={<DefaultPage />} />
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/register" element={<RegisterPage />} />
                    <Route path="/address" element={<AddressPage />} />
                    <Route path="/password" element={<PasswordPage />} />
                    <Route path="/verification" element={<VerificationPage />} />
                    <Route path="/parentlink" element={<ParentLinkPage />} />
                    <Route path="/payment" element={<PaymentPage />} />
                    <Route path="/Parent-link-confirm" element={<ParentLinkConfirmation />} />
                    <Route path="/email-verify" element={<EmailVerify />} />
                    <Route path="/email-verify/add-password" element={<AddPassword />} />
                    <Route path="/add-new-child" element={<AddNewChild />} />
                    <Route path="/password-reset" element={<PasswordReset />} />
                    <Route path="/enter-new-password" element={<EnterNewPassword />} />
                    <Route path="/add-emergancy-contact" element={<AddEmergancyContact />} />
                    <Route path="/emergancy-contact-verify" element={<EmergencyContactVerify />} />

                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/account" element={<AccountPage />} />
                    <Route path="/create-event" element={<CreateEventForm />} />
                    <Route path="/club-info" element={<ClubInfoPage />} />
                    <Route path="/admin" element={<AdminPage />} />
                    <Route path="/childaccountsettings" element={<ChildAccountMannage />} />
                    <Route path="/edit-event" element={<EditEvent />} />
                    <Route path="/manage-member" element={<ManageMember />} />
                    <Route path="/event" element={<EventPage />} />
                    <Route path="/wallet-pass" element={<WalletPass />} />
                    <Route path="/scan-qr" element={<ScanQRPage />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </Router>
        </Suspense>
    );
}

export default App;
