// src/pages/ParentLinkPage.js
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import ParentLinkForm from '../components/ParentLinkForm';
import BackGround from '../components/BackGround';

function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

function ParentLinkPage() {
    const navigate = useNavigate();
    const query = useQuery();
    const userId = query.get('userId');
    const handleParentLinkSuccess = () => {
        navigate(`/login`);
    };
    

    const handleParentLink = async (email, password, relationship ) => {
        try {
            const response = await fetch(`api/auth/link-child/${userId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password, relationship }),
            });
            const data = await response.json();
            if (response.ok) {
                console.log('Parent link successful', data.dob);
                handleParentLinkSuccess(data.userId);
            } else {
                throw new Error(data.message || 'An error occurred during parent link');
            }
        } catch (error) {
            console.error("Parent link error:", error.message);
        }
    };

    return (
            <div>
                <BackGround />
                <h2>Parent Link</h2>
                <ParentLinkForm onParentLink={handleParentLink}/>
            </div>
    );
}

export default ParentLinkPage;