// src/components/ParentLinkForm.js
import React, { useState } from "react";

function ParentLinkForm({ onParentLink }) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [relationship, setRelationship] = useState("");

    const handleSubmit = (event) => {
        event.preventDefault();
        onParentLink(email, password, relationship);
    };

    return (
        <form onSubmit={handleSubmit}>
            <label>
                Parent Email:
                <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
            </label>
            <label>
                Parent Password:
                <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
            </label>
            <label>
              Relationship:
              <input type="text" value={relationship} onChange={(e) => setRelationship(e.target.value)} />
            </label>
            
            <button type="submit">Submit</button>
        </form>
    );
}

export default ParentLinkForm;
