import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function ValidateSession() {
    const navigate = useNavigate();
    const sessionId = localStorage.getItem("sessionId");
    const [error, setError] = useState("");

    useEffect(() => {
        try {
            const validateSessionId = async () => {
                const sessionId = localStorage.getItem("sessionId");

                const response = await fetch("api/auth/validate-session", {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ sessionId }),
                });
                const data = await response.json();
                
                if (!response.ok) {
                    navigate("/login");
                }
            }
            validateSessionId();
        } catch (error) {
            console.error("Verification error:", error);
            setError(error.message);
        }
    });
}

export default ValidateSession;
