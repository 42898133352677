import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';

function RegisterForm({ onRegister }) {
  const [firstName, setFirstName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [gender , setGender] = useState('');
  const [errors, setErrors] = useState({});
  const [toYoungOverlay, setShowOverlayToYoung] = useState(false); // State to track loading state
  const monthInputRef = useRef(null);
  const yearInputRef = useRef(null);

  const validateForm = () => {
    let isValid = true;
    let errors = {};

    if (!firstName) {
      isValid = false;
      errors.firstName = 'First name is required';
    }
    if (!surname) {
      isValid = false;
      errors.surname = 'Surname is required';
    }
    if (!email) {
      isValid = false;
      errors.email = 'Email is required';
    }
    if (!phone) {
      isValid = false;
      errors.phone = 'Phone Number is required';
    }
    if (!day || !month || !year) {
      isValid = false;
      errors.dob = 'Complete date of birth is required';
    }

    if (year < 1900 || year > 2024) {
      isValid = false;
      errors.dob = 'Please enter a valid year';
    }
    if (gender === 'Select' || !gender) {
      isValid = false;
      errors.gender = 'Please select a gender';
    }

    const dobDate = new Date(`${day}-${month.padStart(2, '0')}-${year.padStart(4, '0')}`);
    const currentDate = new Date();
    const thirteenYearsAgo = new Date(currentDate.getFullYear() - 13, currentDate.getMonth(), currentDate.getDate());

    if (dobDate > thirteenYearsAgo) {
      isValid = false;
      setShowOverlayToYoung(true);
    }
    setErrors(errors);
    return isValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) {
      return; // Stop submission if validation fails
    }

    console.log(gender)

    const dob = `${day.padStart(2, '0')}-${month.padStart(2, '0')}-${year}`;
    console.log({ day, month, year, dob });

    // Simulate async operation with timeout
    await new Promise(resolve => setTimeout(resolve, 2000));

    onRegister(firstName, surname, email, phone, dob, gender);
  };

  const handleDayChange = (e) => {
    const value = e.target.value.replace(/\D/, ''); // Remove non-digit characters
    if (value.length <= 2) {
      setDay(value);
      if (value.length === 2) {
        monthInputRef.current.focus();
      }
    }
  };

  const handleMonthChange = (e) => {
    const value = e.target.value.replace(/\D/g, ''); // Remove all non-digit characters
    if (value.length <= 2) {
      setMonth(value);
      if (value.length === 2) {
        const monthValue = parseInt(value, 10);
        if (monthValue >= 1 && monthValue <= 12) {
          yearInputRef.current.focus();
        }
      }
    }
  };

  const handleYearChange = (e) => {
    const value = e.target.value.replace(/\D/g, ''); // Remove all non-digit characters
    if (value.length <= 4) {
      setYear(value);
      const yearValue = parseInt(value, 10);
      if (yearValue < 1920 || yearValue > new Date().getFullYear()) {
        setErrors(prev => ({ ...prev, dob: 'Please enter a valid year' }));
      } else {
        setErrors(prev => ({ ...prev, dob: undefined }));
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <input type="text" id="firstName" value={firstName} placeholder='First Name' onChange={(e) => setFirstName(e.target.value)} />
        {errors.firstName && <div style={{ color: 'red' }}>{errors.firstName}</div>}
      </div>
      <div>
        <input type="text" id="surname" placeholder='Surname' value={surname} onChange={(e) => setSurname(e.target.value)} />
        {errors.surname && <div style={{ color: 'red' }}>{errors.surname}</div>}
      </div>
      <div>
        <input type="email" id="email" placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} />
        {errors.email && <div style={{ color: 'red' }}>{errors.email}</div>}
      </div>
      <div>
        <input type="tel" id="phone" placeholder='Phone' value={phone} onChange={(e) => setPhone(e.target.value)} />
        {errors.phone && <div style={{ color: 'red' }}>{errors.phone}</div>}
      </div>
      <div className='dob'>
        <input type="text" id="day" placeholder='dd' value={day} onChange={handleDayChange} />
        <input type="text" id="month" placeholder='mm' value={month} onChange={handleMonthChange} ref={monthInputRef} />
        <input type="text" id="year" placeholder='yyyy' value={year} onChange={handleYearChange} ref={yearInputRef} />
      </div>
      {errors.dob && <div style={{ color: 'red' }}>{errors.dob}</div>}
      <div>
        <label style={{fontSize: "18px"}} htmlFor="gender">Gender</label>
        <select
          id="gender"
          name="gender"
          defaultValue="Select"
          onChange={(e) => setGender(e.target.value)}
        >
          <option value="Select" disabled>Select</option>
          <option value="Male">Male</option>
          <option value="Female">Female</option>
        </select>
        {errors.gender && <div style={{ color: 'red' }}>{errors.gender}</div>}
        <p style={{fontSize: "12px"}} >(this will be used for event categories)</p>
      </div>
      <Link to="/login">Already have an account? Login</Link>
      
      <button type="submit">Next</button>
      {toYoungOverlay && <div className="overlay">
        <div>
          <h3>To Young</h3>
          <p>You must be 13 to have your own account please ask a parent/carer to register you with the club in their account</p>
          <p>Go to account → child account → add child and enter child details</p>
          <button onClick={() => setShowOverlayToYoung(false)}>Okay</button>
        </div>
      </div>
      }
    </form>
  );
}

export default RegisterForm;
