import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import BackGround from "../components/BackGround";

function AddEmergancyContact() {
    const [firstName, setFirstName] = useState('');
    const [surname, setSurname] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [relationship, setRelationship] = useState('');
    const [medical, setMedical] = useState('');
    const [hasMedical, setHasMedical] = useState(true);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const validateForm = () => {
        let isValid = true;
        let errors = {};

        if (!firstName) {
            isValid = false;
            errors.firstName = 'First name is required';
        }
        if (!surname) {
            isValid = false;
            errors.surname = 'Surname is required';
        }
        if (!email) {
            isValid = false;
            errors.email = 'Email is required';
        }
        if (!phone) {
            isValid = false;
            errors.phone = 'Phone Number is required';
        }
        if (!relationship) {
            isValid = false;
            errors.relationship = 'Relationship is required';
        }
        setErrors(errors);
        return isValid;
    };

    const handleSubmit = async (event) => {
        setIsLoading(true);
        event.preventDefault();
        if (!validateForm()) {
            return; // Stop submission if validation fails
        }

        let name = `${firstName} ${surname}`;

        await new Promise(resolve => setTimeout(resolve, 2000));

        try {
            const response = await fetch('api/auth/add-emergancy-contact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ name, email, phone, relationship, sessionId: localStorage.getItem('sessionId'), medical}),
            });
            const data = await response.json();
            if (response.ok) {
                localStorage.setItem('sessionId', data.sessionToken);
                const destination = data.child ? '/login' : `/payment?userId=${data.userId}`;
                navigate(destination);
            } else {
                throw new Error(data.message || 'An error occurred');
            }
        } catch (data) {
            console.error("error:", data.message);
            setError(data.message);
            setIsLoading(false);
        }

    };

    return (
        <>
            <BackGround />
            <h1>Emergency Contact</h1>
            <form onSubmit={handleSubmit}>
                <div>
                    <input type="text" id="firstName" value={firstName} placeholder='First Name' onChange={(e) => setFirstName(e.target.value)} />
                    {errors.firstName && <div style={{ color: 'red' }}>{errors.firstName}</div>}
                </div>
                <div>
                    <input type="text" id="surname" placeholder='Surname' value={surname} onChange={(e) => setSurname(e.target.value)} />
                    {errors.surname && <div style={{ color: 'red' }}>{errors.surname}</div>}
                </div>
                <div>
                    <input type="tel" id="phone" placeholder='Phone' value={phone} onChange={(e) => setPhone(e.target.value)} />
                    {errors.phone && <div style={{ color: 'red' }}>{errors.phone}</div>}
                </div>
                <div>
                    <input type="email" id="email" placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} />
                    {errors.email && <div style={{ color: 'red' }}>{errors.email}</div>}
                </div>
                <div>
                    <input type="text" id="relationship" placeholder='Relationship' value={relationship} onChange={(e) => setRelationship(e.target.value)} />
                    {errors.relationship && <div style={{ color: 'red' }}>{errors.relationship}</div>}
                </div>
                <div>
                    <h2>Medical Conditions</h2>
                    <label style={{ fontSize: "18px" }} htmlFor="none">None</label>
                    <input style={{ margin: "10px" }} className="checkbox" type="checkbox" id="none" name="none" value="none" onChange={() => setHasMedical(!hasMedical)} />
                    {hasMedical && <input type="text" id="medical" placeholder='Medical Conditions' value={medical} onChange={(e) => setMedical(e.target.value)} />}
                </div>

                <button type="submit">Next</button>
                {isLoading && <div className="overlay"></div>}
                {isLoading && <div className="spin"></div>}
                {error && <p className='error' >{error}</p>}
            </form>
        </>
    );
}

export default AddEmergancyContact;