import React, { useState } from "react";
import { useLocation } from "react-router-dom";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function ConfirmChildLink() {
    const query = useQuery();
    const [message, setMessage] = useState("");
    const linkId = query.get("userId");

    const confirmLink = async () => {
        try {
            const response = await fetch(`api/auth/link-child-confirm/${linkId}`, {
                method: "POST",
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.message);
            }

            setMessage(data.message);
        } catch (error) {
            setMessage(error.message);
        }
    };


    return (
        <div className="plc-main-c">
            <div className="plc-container">
                <h1 className="plc-h1">Confirm Child Account Link</h1>

                <p className="PLC-P">Click the button below to confirm the child account link</p> 
                <button className="plc-button" onClick={confirmLink}>
                    Confirm Verification
                </button>
                {message && <p>{message}</p>}
            </div>
        </div>
    );
}

export default ConfirmChildLink;
