import React from "react";
import { useLocation } from "react-router-dom";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function EmergencyContactVerify() {
    const query = useQuery();
    const userId = query.get("userId");
    const [message, setMessage] = React.useState(null);

    const confirmLink = async () => {
        try {
            const response = await fetch("/api/auth/emergancy-contact-verify", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ userId }),
            });
            const data = await response.json();
            if (response.ok) {
                setMessage("You have confirmed you are the emergancy contact");
            } else {
                throw new Error(data.message || "An error occurred during registration");
            }
        } catch (data) {
            console.error("Registration error:", data.message);
            setMessage(data.message);
        }
    }

    return (
        <div className="plc-main-c">
            <div className="plc-container">
                <h1 className="plc-h1">Confirm Emergency Contact</h1>

                <p className="PLC-P">Click the button below to confirm you are the emergency contact</p> 
                <button className="plc-button" onClick={confirmLink}>
                    Confirm
                </button>
                {message && <p>{message}</p>}
            </div>
        </div>
    );
}

export default EmergencyContactVerify;