
import React, { useState } from 'react';

function AddressForm({ onSubmitAddress }) {
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [townCity, setTownCity] = useState('');
  const [postcode, setPostcode] = useState('');
  const [errors, setErrors] = useState({});
  const validateForm = () => {
    let isValid = true;
    let errors = {};

    if (!addressLine1) {
      isValid = false;
      errors.addressLine1 = 'Please enter Address line 1';
    }
    if (!addressLine2) {
      isValid = false;
      errors.addressLine2 = 'Please enter Address line 2';
    }
    if (!townCity) {
      isValid = false;
      errors.townCity = 'Please enter a town/city';
    }
    if (!postcode) {
      isValid = false;
      errors.postcode = 'Please enter your postcode';
    }
    setErrors(errors);
    return isValid;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!validateForm()) {
      return; // Stop submission if validation fails
    }
    onSubmitAddress({ addressLine1, addressLine2, townCity, postcode });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <input type="text" placeholder='Address Line 1' id="addressLine1" value={addressLine1} onChange={(e) => setAddressLine1(e.target.value)} />
        {errors.addressLine1 && <div style={{ color: 'red' }}>{errors.addressLine1}</div>}
      </div>
      <div>
        <input type="text" placeholder='Address Line 1' id="addressLine2" value={addressLine2} onChange={(e) => setAddressLine2(e.target.value)} />
        {errors.addressLine2 && <div style={{ color: 'red' }}>{errors.addressLine2}</div>}
      </div>
      <div>
        <input type="text" id="townCity" placeholder='Town/City' value={townCity} onChange={(e) => setTownCity(e.target.value)}/>
        {errors.townCity && <div style={{ color: 'red' }}>{errors.townCity}</div>}
      </div>
      <div>
        <input type="text" id="postcode" placeholder='Postcode' value={postcode} onChange={(e) => setPostcode(e.target.value)} />
        {errors.postcode && <div style={{ color: 'red' }}>{errors.postcode}</div>}
      </div>
      <button type="submit">Next</button>
    </form>
  );
}

export default AddressForm;
