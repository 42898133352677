import React, { useState } from "react";
import ValidateSession from "../components/ValidateSession";
import LeftNavBar from "../components/LeftNavbar";
import addToWallet from "../images/enGB_add_to_google_wallet_add-wallet-badge.png";

// Define a React component
const WalletPass = () => {
    const [deviceType, setDeviceType] = useState("");
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [passData, setPassData] = useState("");
    const [showAnimation, setShowAnimation] = useState(false); // State to show animation
    const passLogo = "https://account.gallowayhillbillies.org/favicon.ico";

    ValidateSession();

    // Detect device type
    const detectDevice = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (/android/i.test(userAgent)) {
            setDeviceType("android");
        } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            setDeviceType("ios");
        } else {
            setDeviceType("other");
        }
    };

    const getPassDetails = () => {
        const sessionId = localStorage.getItem("sessionId");
        setIsLoading(true);
        fetch("api/auth/get-pass-details", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ sessionId })
        })
            .then((response) => {
                if (!response.ok) {
                    setIsLoading(false);
                    throw new Error("An error occurred. Please try again.");
                }
                return response.json();
            })
            .then((data) => {
                console.log("Pass details:", data);
                setPassData(data);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching pass details:", error);
                setError("An error occurred. Please try again.", error);
                setIsLoading(false);
            });
    };
    const fetchWalletUrl = async () => {
        const sessionId = localStorage.getItem("sessionId");
        try {
            setIsLoading(true);
            const response = await fetch("api/auth/create-wallet-pass", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ sessionId, deviceType })
            });

            if (!response.ok) {
                setIsLoading(false);
                throw new Error("An error occurred. Please try again.");
            }

            if (deviceType === "ios") {
                // Response will be a file, so get the blob
                const blob = await response.blob();

                // Create a URL for the file
                const url = window.URL.createObjectURL(blob);

                // Download the file
                const link = document.createElement("a");
                link.href = url;
                link.download = "membership.pkpass";
                link.click();

                window.URL.revokeObjectURL(url);
                setTimeout(() => {
                    setShowAnimation(true);
                }, 1500);
            } else if (deviceType === "android") {
                // Response will be a JSON containing the link
                const data = await response.json();
                const url = data.walletUrl;

                // Open the link in a new tab
                window.open(url, "_blank");
                console.log("Wallet URL:", url);
            }

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error("Error fetching wallet pass:", error);
            setError("An error occurred. Please try again.", error);
        }
    };

    // Call device detection on component mount
    React.useEffect(() => {
        detectDevice();
        getPassDetails();
    }, []);

    const renderPreview = () => {
        return (
            <div style={styles.passContainer}>
                <LeftNavBar />
                <div style={{ ...styles.passHeader, backgroundColor: "#61006f" }}>
                    <img src={passLogo} alt="Logo" style={styles.passLogo} />
                    <h2 style={styles.passTitle}>GHBC Membership</h2>
                </div>
                <div style={styles.passBody}>
                    <p style={styles.passHeaderText}>Name</p>
                    <p style={styles.passHeaderTextT}>{passData.header}</p>
                    <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                        <p
                            style={{
                                marginTop: "5px",
                                marginBottom: "0",
                                fontSize: "12px",
                                color: "white",
                                alignSelf: "flex-end"
                            }}
                        >
                            ID
                        </p>
                        <p style={{ marginTop: "5px", marginBottom: "0", fontSize: "12px", color: "white" }}>Expire</p>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <p
                            style={{
                                marginTop: "0px",
                                marginBottom: "0",
                                fontSize: "14px",
                                color: "white",
                                alignSelf: "flex-end"
                            }}
                        >
                            {passData.subheader}
                        </p>
                        <p style={{ marginTop: "0px", marginBottom: "0", fontSize: "14px", color: "white" }}>
                            1/4/{new Date().getFullYear() + 1}
                        </p>
                    </div>
                </div>
                <div style={styles.passFooter}>
                    <p style={{ fontSize: "16px", fontWeight: "light" }}></p>
                </div>
            </div>
        );
    };

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <h1>Wallet Pass</h1>
            {renderPreview()}
            {deviceType === "ios" ? (
                <img
                    src="https://cdsassets.apple.com/live/7WUAS350/images/ios/add-to-apple-wallet-logo.png"
                    alt="Add to Apple Wallet"
                    style={{ alignSelf: "center", cursor: "pointer", width: "200px", marginTop: "30px" }}
                    onClick={fetchWalletUrl}
                />
            ) : deviceType === "android" ? (
                <>
                   <img
                    src={addToWallet}
                    alt="Add to Google Wallet"
                    style={{ alignSelf: "center", cursor: "pointer", width: "200px", marginTop: "30px" }}
                    onClick={fetchWalletUrl} />
                </>
            ) : (
                <div
                    style={{
                        textAlign: "center",
                        alignSelf: "center",
                        marginTop: "30px",
                        width: "300px",
                        fontSize: "20px"
                    }}
                >
                    The "Add to Wallet" feature is only available on mobile devices <br />
                </div>
            )}
            {isLoading && <div className="overlay"></div>}
            {isLoading && <div className="spin"></div>}
            {error && <div className="error">{error}</div>}

            {showAnimation && (
                <>
                    <div className="phone-container">
                        <div className="side-button"></div>
                        <div className="message">Double-click the side button to view the card</div>
                    </div>
                    <div className="overlay" onClick={() => setShowAnimation(false)}></div>
                </>
            )}
        </div>
    );
};

const styles = {
    passContainer: {
        width: "300px",
        borderRadius: "20px",
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
        marginTop: "20px",
        overflow: "hidden"
    },
    passHeader: {
        display: "flex",
        alignItems: "center",
        padding: "10px"
    },
    passLogo: {
        height: "40px",
        marginRight: "10px"
    },
    passTitle: {
        fontSize: "18px",
        margin: 0,
        color: "#fff"
    },
    passBody: {
        padding: "15px",
        backgroundColor: "#61006f"
    },
    passHeaderText: {
        fontSize: "16px",
        color: "#fff",
        margin: 0
    },
    passHeaderTextT: {
        fontSize: "22px",
        fontWeight: "lighter",
        color: "#fff",
        margin: 0
    },
    passFooter: {
        padding: "10px",
        backgroundColor: "#61006f",
        textAlign: "center",
        fontSize: "4px",
        color: "#fff",
        fontWeight: "light"
    },
    animationContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "20px"
    },
    animationGif: {
        width: "200px",
        height: "auto"
    },
    animationText: {
        marginTop: "10px",
        fontSize: "16px",
        textAlign: "center"
    }
};

export default WalletPass;
