// src/pages/AddressPage.js
import React from 'react';
import AddressForm from '../components/AddressForm';
import { useNavigate } from 'react-router-dom';
import BackGround from '../components/BackGround';

function AddressPage() {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const handleAddressSuccess = () => {
    navigate(`/password`);
  };

  const encryptAndSubmitAddress = async (addressData) => {
    setLoading(true);
    sessionStorage.setItem('address', JSON.stringify(addressData));
    setLoading(false);
    handleAddressSuccess();
  };

  return (
    <div>
      <BackGround />
      <h2>Your Address</h2>
      <AddressForm onSubmitAddress={encryptAndSubmitAddress} />
      {loading && <div className="overlay"></div>}
      {loading && <div className="spin"></div>}
    </div>
  );
}

export default AddressPage;