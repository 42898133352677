import React from "react";
import BackGround from "../components/BackGround";

function NotFound() {
    return (
        <div>
            <BackGround />
            <h1>404 - Not Found!</h1>
            <p>Sorry, the page you are looking for does not exist.</p>

            <a href='/login' ><button style={{ marginTop: "20px" }}>Back to Home</button></a>
        </div>
    );
}

export default NotFound;