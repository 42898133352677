import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PaymentForm from "../components/PaymentForm";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import BackGround from "../components/BackGround";

// Load the Stripe library and create a promise for initializing Stripe
/* const stripePromise = loadStripe("pk_live_51PBLteEBCAgwgTMtU7towoFhUWQPHTz3pneiKCPEkwXvyaJ3RvjULhHdheWM80OjfhkUYpH0mDPdLcdDB2WD3wb200qaY7HEhe"); */
const stripePromise = loadStripe("pk_live_51L2g8tH5Ksq6KYWdGNjmUSA9nnlnwm6reJUl4lR3I4kfhg4ojDRpIeAPAClUaOItwPmvj5Smmr2jYBkrj1OTOlP300ixxEdqyC"); 

// Custom hook to get query parameters from the URL
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function PaymentPage() {
  const query = useQuery();
  const userId = query.get("userId");
  const [clientSecret, setClientSecret] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setLoading(true);
    try {
      fetch(`api/auth/create-payment-intent/${userId}`, {
        method: 'POST',
      })
        .then(response => {
          if (response.status === 409) {
            throw new Error("User has already paid");
          }
          return response.json();
        })
        .then(data => setClientSecret(data.client_secret))
        .catch(error => setError(error.message));
    } catch (error) {
      setError(error.message || `Error: ${error.status}`);
    }
    setLoading(false);
  }, [userId]);
  
  return (
    <div>
      <BackGround />
      <h2>Payment</h2>
      
      {error && <div><p>{error}</p><a href="/login" ><button>Go to login page</button></a></div>}
      {clientSecret && (
        // Render the payment form if the client secret is available
        <Elements stripe={stripePromise} options={{clientSecret: clientSecret}}>
          <PaymentForm clientSecret={clientSecret} />
        </Elements>
      )}
      {error && <div><p>{error}</p></div>}
      {loading && <div className="overlay"></div>}
      {loading && <div className="spin"></div>}

    </div>
  );
}

export default PaymentPage;
