import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import PasswordForm from "../components/PasswordForm";
import BackGround from "../components/BackGround";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function AddPassword() {
  const query = useQuery();
  const userId = query.get("userId");
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmitPassword = async (password) => {
    setLoading(true);
    try {
      const response = await fetch(`/api/auth/users/${userId}/password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ password }),
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "An error occurred. Please try again.");
      }
      localStorage.setItem("sessionId", data.sessionId);
      navigate(`/add-emergancy-contact`);
    } catch (err) {
      setError(err.message);
      setLoading(false);
      if (
        err.message ===
        "Password already set for this user redirecting to login"
      ) {
        setTimeout(() => {
          navigate(`/login`);
        }, 3000);
      }
    }
  };

  return (
    <div>
      <BackGround />
      <h2>Set Your Password</h2>
      <PasswordForm onSubmitPassword={handleSubmitPassword} />
      {error && <p className="error">{error}</p>}
      {isLoading && <div className="overlay"></div>}
      {isLoading && <div className="spin"></div>}
    </div>
  );
}

export default AddPassword;
