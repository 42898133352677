import CreateEventForm from '../components/CreateEventForm';
import { useNavigate } from 'react-router-dom';
import NavBar from "../components/NavBar";

function CreateEventPage() {
  const navigate = useNavigate();

  const SendData = async (Data) => {
    const EventData = JSON.stringify(Data);

    try {
      const response = await fetch(`api/auth/create-event`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: EventData,
      })
        .then(response => response.json())
        .then(navigate('/dashboard'));

      if (!response.ok) throw new Error('Failed to submit address'); {
        console.log('Registration successful', Data);
        navigate('/dashboard');
      }
    } catch (error) {
      console.error("Address submission error:", error);
      navigate('/dashboard');
    }
  };


  return (
    <div>
      <CreateEventForm onSubmitData={SendData} />
      <NavBar />
    </div>
  );
}

export default CreateEventPage