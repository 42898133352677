import React from 'react';
import Card from 'react-bootstrap/Card';
import NavBar from "../components/NavBar";


function ClubInfoPage() {
    const policies = [
        { title: 'Communications Policy', buttonLink: 'https://www.gallowayhillbillies.org/images/pdf/CommunicationsPolicyV2.pdf' },
        { title: 'Members Code Of Conduct', buttonLink: 'https://www.gallowayhillbillies.org/images/pdf/MembersCodeofConduct.pdf' },
        { title: 'Anti Bullying Policy', buttonLink: 'https://www.gallowayhillbillies.org/images/pdf/ScottishCyclingAntiBullyingPolicyv1.01.pdf' },
        { title: 'Parents Guardians Code Of Conduct', buttonLink: 'https://www.gallowayhillbillies.org/images/pdf/ParentsGuardiansCodeofConduct.pdf' },
        { title: 'Data Use Policy', buttonLink: 'https://www.gallowayhillbillies.org/images/pdf/DataUsePolicy.pdf' },
        { title: 'Equality Policy', buttonLink: 'https://www.gallowayhillbillies.org/images/pdf/EqualityPolicy.pdf' },
        { title: 'Disciplinary Policy', buttonLink: 'https://www.gallowayhillbillies.org/images/pdf/DisciplinaryPolicy.pdf' },
        { title: 'Constitution', buttonLink: 'https://www.gallowayhillbillies.org/images/pdf/Constitution.pdf' },
        { title: 'Coach Rideleader Code Of Conduct', buttonLink: 'https://www.gallowayhillbillies.org/images/pdf/CoachRideLeaderCodeofConduct.pdf' },
    ];

    return (
        <>
            <a href='/dashboard' ><button style={{ margin: "20px" }}>Back to Home</button></a>
            <h1 align="center" >Club Information</h1>
            <div style={{ display: "flex", flexWrap: "wrap" }} >
                {policies.map(policy => (
                    <a target='_blank' href={policy.buttonLink} style={{ flexGrow: "1", display: "flex" }} >
                        <Card key={policy.title}>
                            <Card.Body>
                                <Card.Text>
                                    <h2>{policy.title}<svg xmlns="http://www.w3.org/2000/svg" style={{ marginLeft: "10px" }} width="20px" viewBox="0 0 512 512"><path d="M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h82.7L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3V192c0 17.7 14.3 32 32 32s32-14.3 32-32V32c0-17.7-14.3-32-32-32H320zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z" /></svg></h2>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </a>
                ))}
            </div><NavBar /> </>
    );

}

export default ClubInfoPage;