import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Dashboard() {
    const navigate = useNavigate();

    useEffect(() => {
        const validateSessionId = async () => {
            const sessionId = localStorage.getItem("sessionId");

            const response = await fetch("api/auth/validate-session", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ sessionId }),
            });

            if (!response.ok) {
                navigate("/login");
            } else {
                navigate("/dashboard");
            }
        };
        validateSessionId();
    }, [navigate]);

    return (
        <div>
            <div className="overlay"></div>
            <div className="spin"></div>
        </div>
    );
}

export default Dashboard;
