import React, { useEffect, useState } from "react";
import ValidateSession from "../components/ValidateSession";
import NavBar from "../components/NavBar";

function Dashboard() {
    
    const [isLoading, setIsLoading] = useState(false);
    const [toggleChecked, setToggleChecked] = useState(false);
    const [details, setDetails] = useState("");
    const [events, setEvents] = useState([]);
    const [noEvent, setNoEvent] = useState(false);
    const [joinedEvents, setJoinedEvents] = useState([]);
    const [noJoinedEvent, setNoJoinedEvent] = useState(false);
    const [userAdmin, setUserAdmin] = useState(false);
    
    ValidateSession();

    function onLeaveEventClick(event) {
        setIsLoading(true);
        const sessionId = localStorage.getItem("sessionId");
        const eventId = event.target.id; // get the button's ID

        fetch("api/auth/leave-event", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ sessionId, eventId }), // send eventId along with sessionId
        })
            .then((response) => response.json())
            .then((data) => {
                if (Array.isArray(data.events)) {
                    setEvents(data.events);
                } else {
                    console.error("data.events is not an array:", data.events);
                    setNoEvent(true);
                }
                if (Array.isArray(data.joined_events)) {
                    setJoinedEvents(data.joined_events);
                } else {
                    console.error("data.joined_events is not an array:", data.joined_events);
                    setNoJoinedEvent(true);
                }
                setIsLoading(false);
            })
            .catch((error) => {
                console.error("Error:", error);
                setIsLoading(false);
            });
    }


    function onJoinEventClick(event) {
        setIsLoading(true);
        const sessionId = localStorage.getItem("sessionId");
        const eventId = event.target.id; // get the button's ID

        fetch("api/auth/join-event", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ sessionId, eventId }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (Array.isArray(data.events)) {
                    setEvents(data.events);
                } else {
                    console.error("data.events is not an array:", data.events);
                    setNoEvent(true);
                }
                if (Array.isArray(data.joined_events)) {
                    setJoinedEvents(data.joined_events);
                } else {
                    console.error("data.joined_events is not an array:", data.joined_events);
                    setNoJoinedEvent(true);
                }
                setIsLoading(false);
            })
            .catch((error) => {
                console.error("Error:", error);
                setIsLoading(false);
            });
    }

    useEffect(() => {
        
        setIsLoading(true);
        const sessionId = localStorage.getItem("sessionId");

        fetch("api/auth/load-dashboard", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ sessionId }),
        })
            .then((response) => response.json())
            .then((data) => {
                setDetails(data.user_name);
                if (Array.isArray(data.events)) {
                    setEvents(data.events);
                } else {
                    console.error("data.events is not an array:", data.events);
                    setNoEvent(true);
                }
                if (Array.isArray(data.joined_events)) {
                    setJoinedEvents(data.joined_events);
                } else {
                    console.error("data.joined_events is not an array:", data.joined_events);
                    setNoJoinedEvent(true);
                }
                console.log(data);
            })
            .catch((error) => {
                console.error("Error:", error);
            })
            .finally(() => {
                setIsLoading(false);
            });

        fetch("api/auth/is-admin", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ sessionId }),
        }).then((response, data) => {
            if (response.ok) {
                console.log("User is an admin");
                setUserAdmin(true);
            } else {
                console.log("User is not an admin");
            }
        });

        function toggleEvents() {
            const upcomingEvents = document.getElementById("upcoming");
            const joinedEvents = document.getElementById("joined");

            if (window.innerWidth <= 999) {
                if (toggleChecked) {
                    upcomingEvents.style.display = "none";
                    joinedEvents.style.display = "block";
                } else {
                    upcomingEvents.style.display = "block";
                    joinedEvents.style.display = "none";
                }
            } else {
                joinedEvents.style.display = "block";
                upcomingEvents.style.display = "block";
            }
        }

        toggleEvents();

        window.addEventListener("resize", toggleEvents);

        return () => {
            window.removeEventListener("resize", toggleEvents);
        };
    }, [toggleChecked]);

    return (
        <div>

            <div className="Header-image">
                <div>
                    <img
                        src="https://gallowayhillbillies.org/images/background/00EE0F63-2186-4624-9878-DB5484A9450F.jpg"
                        alt=""
                    />
                </div>
                <h1 className="header-txt">Welcome {details}</h1>
            </div>
            <div className="header-box">
                <a className="box-home" href="/club-info">
                    <h2 style={{ textAlign: "center", marginRight: 10 }}>Club Info</h2>
                    <svg xmlns="http://www.w3.org/2000/svg" width="70px" fill="white" viewBox="0 0 640 512">
                        <path d="M400 96a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm27.2 64l-61.8-48.8c-17.3-13.6-41.7-13.8-59.1-.3l-83.1 64.2c-30.7 23.8-28.5 70.8 4.3 91.6L288 305.1V416c0 17.7 14.3 32 32 32s32-14.3 32-32V288c0-10.7-5.3-20.7-14.2-26.6L295 232.9l60.3-48.5L396 217c5.7 4.5 12.7 7 20 7h64c17.7 0 32-14.3 32-32s-14.3-32-32-32H427.2zM56 384a72 72 0 1 1 144 0A72 72 0 1 1 56 384zm200 0A128 128 0 1 0 0 384a128 128 0 1 0 256 0zm184 0a72 72 0 1 1 144 0 72 72 0 1 1 -144 0zm200 0a128 128 0 1 0 -256 0 128 128 0 1 0 256 0z" />
                    </svg>
                </a>
                <a
                    href="https://www.gallowayhillbillies.org/images/pdf/riderCodeofConductV2.pdf"
                    target="_blank"
                    className="box-home"
                    rel="noreferrer"
                >
                    <h2 style={{ textAlign: "center", marginRight: 10 }}>Code of Conduct</h2>
                    <svg xmlns="http://www.w3.org/2000/svg" width="85px" fill="white" viewBox="0 0 16 16">
                        <path d="M 8 3 C 6.3490681 3 5 4.3490681 5 6 C 5 6.9603906 5.4649627 7.8094412 6.171875 8.359375 C 5.7461181 8.527912 5.3556179 8.7564502 4.9960938 9.0292969 C 4.9962435 9.0192988 5 9.0100315 5 9 C 5 7.9013528 4.0986472 7 3 7 C 1.9013528 7 1 7.9013528 1 9 C 1 9.5359198 1.2172227 10.022505 1.5644531 10.382812 C 0.63783253 10.894611 0 11.8711 0 13 L 1 13 C 1 11.889094 1.8890941 11 3 11 C 3.1386918 11 3.2724373 11.014349 3.4023438 11.041016 C 3.1445003 11.643242 3 12.304957 3 13 L 4 13 C 4 10.785288 5.7852884 9 8 9 C 10.214712 9 12 10.785288 12 13 L 13 13 C 13 12.307124 12.855934 11.647599 12.599609 11.046875 C 12.729086 11.020489 12.861339 11 13 11 C 14.110906 11 15 11.889094 15 13 L 16 13 C 16 11.8711 15.362167 10.894611 14.435547 10.382812 C 14.782777 10.022505 15 9.5359198 15 9 C 15 7.9013528 14.098647 7 13 7 C 11.901353 7 11 7.9013528 11 9 C 11 9.0100315 11.003756 9.0192988 11.003906 9.0292969 C 10.644382 8.7564502 10.253882 8.527912 9.828125 8.359375 C 10.535037 7.8094412 11 6.9603906 11 6 C 11 4.3490681 9.6509319 3 8 3 z M 8 4 C 9.1104918 4 10 4.8895082 10 6 C 10 7.1104918 9.1104918 8 8 8 C 6.8895082 8 6 7.1104918 6 6 C 6 4.8895082 6.8895082 4 8 4 z M 3 8 C 3.5582071 8 4 8.4417929 4 9 C 4 9.5582071 3.5582071 10 3 10 C 2.4417929 10 2 9.5582071 2 9 C 2 8.4417929 2.4417929 8 3 8 z M 13 8 C 13.558207 8 14 8.4417929 14 9 C 14 9.5582071 13.558207 10 13 10 C 12.441793 10 12 9.5582071 12 9 C 12 8.4417929 12.441793 8 13 8 z" />
                    </svg>
                </a>
                <a className="box-home" href="/account">
                    <h2 style={{ textAlign: "center", marginRight: 10 }}>Account</h2>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="50px" viewBox="0 0 448 512">
                        <path d="M304 128a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM49.3 464H398.7c-8.9-63.3-63.3-112-129-112H178.3c-65.7 0-120.1 48.7-129 112zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3z" />
                    </svg>
                </a>
                {userAdmin && (
                    <a className="box-home" href="/admin">
                        <h2 style={{ textAlign: "center", marginRight: 10 }}>Admin Panel</h2>
                        <svg xmlns="http://www.w3.org/2000/svg" width="50px" fill="white" viewBox="0 0 512 512">
                            <path d="M352 320c88.4 0 160-71.6 160-160c0-15.3-2.2-30.1-6.2-44.2c-3.1-10.8-16.4-13.2-24.3-5.3l-76.8 76.8c-3 3-7.1 4.7-11.3 4.7H336c-8.8 0-16-7.2-16-16V118.6c0-4.2 1.7-8.3 4.7-11.3l76.8-76.8c7.9-7.9 5.4-21.2-5.3-24.3C382.1 2.2 367.3 0 352 0C263.6 0 192 71.6 192 160c0 19.1 3.4 37.5 9.5 54.5L19.9 396.1C7.2 408.8 0 426.1 0 444.1C0 481.6 30.4 512 67.9 512c18 0 35.3-7.2 48-19.9L297.5 310.5c17 6.2 35.4 9.5 54.5 9.5zM80 408a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                        </svg>
                    </a>
                )}
            </div>
            <input
                type="checkbox"
                id="toggle"
                className="toggleCheckbox"
                checked={toggleChecked}
                onChange={(e) => setToggleChecked(e.target.checked)}
            />
            <label htmlFor="toggle" className="toggleContainer">
                <div>Upcoming Events</div>
                <div>Joined Events</div>
            </label>
            <div>
                <div className="uje">
                    <div className="eventbar" id="upcoming">
                        <h2>Upcoming Events</h2>
                        {noEvent && <p>No Upcoming Events</p>}
                        {events.map((event) => (
                            <div class="event">
                                <h3>{event.name}</h3>
                                <p id="Time">Time: {event.time}</p>
                                <p id="shortDescription">{event.short_description}</p>
                                <a href={`/event?id=${event.id}`}><button id={event.id}>View Event</button></a>
                                <button onClick={onJoinEventClick} id={event.id}>
                                    Join Event
                                </button>
                            </div>
                        ))}
                    </div>
                    <div className="eventbar" id="joined">
                        <h2>Joined Events</h2>
                        {noJoinedEvent && <p>You have not joined any events</p>}
                        {joinedEvents.map((joined_events) => (
                            <div class="event">
                                <h3>{joined_events.name}</h3>
                                <p id="category">Category: {joined_events.category}</p>
                                <p id="Time">Time: {joined_events.time}</p>
                                <p id="shortDescription">{joined_events.short_description}</p>
                                <a href={`/event?id=${joined_events.id}`}><button id={joined_events.id}>View Event</button></a>
                                <button onClick={onLeaveEventClick} id={joined_events.id}>Leave Event</button>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <NavBar />
            {isLoading && <div className="overlay"></div>}
            {isLoading && <div className="spin"></div>}
        </div>
    );
}

export default Dashboard;
