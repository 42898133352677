// src/pages/RegisterPage.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import RegisterForm from '../components/RegisterForm';
import BackGround from '../components/BackGround';

function RegisterPage() {
  const navigate = useNavigate();
  const [error, setError] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const handleRegisterSuccess = () => {
    navigate(`/address`);
  };

  const handleRegister = async (firstName, surname, email, phone, dob, gender) => {
    setIsLoading(true);
    try {
      const response = await fetch('api/auth/check-data', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, phone}),
      });
      const data = await response.json();
      if (response.ok) {
        console.log('Registration successful');
        sessionStorage.setItem('firstName', firstName);
        sessionStorage.setItem('surname', surname);
        sessionStorage.setItem('email', email);
        sessionStorage.setItem('phone', phone);
        sessionStorage.setItem('gender', gender);
        sessionStorage.setItem('dob', dob);
        handleRegisterSuccess();

      } else {
        throw new Error(data.message || 'An error occurred during registration');
      }
    } catch (data) {
      console.error("Registration error:", data.message);
      setError(data.message);
      setIsLoading(false);
    }
  };

  return (
      <div>
        <BackGround />
        <h2>Register</h2>
        <RegisterForm onRegister={handleRegister} />
        {error && <p style={{maxWidth: "250px", fontSize:"20px"}} className='error' >{error}</p>}
        {isLoading && <div className="overlay"></div>}
      {isLoading && <div className="spin"></div>} 
      </div>
  );
}

export default RegisterPage;
