import React, { useState } from 'react';

function VerificationForm({ onSubmitVerification, userId }) {
  const [code, setCode] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!code) {
      setError('Please enter the verification code.');
      return;
    }
    setError('');
    onSubmitVerification(userId, code);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <input type="text" id="verificationCode" placeholder='Verification Code' value={code} onChange={(e) => setCode(e.target.value)} />
        {error && <div style={{ color: 'red' }}>{error}</div>}
      </div>
      <button type="submit">Verify</button>
    </form>
  );
}

export default VerificationForm;