import React, { useState } from "react";
import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";
import { useNavigate, useLocation } from "react-router-dom";

// Custom hook to get query parameters from the URL
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function PaymentForm({ clientSecret }) {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const query = useQuery();
  const stripe = useStripe();
  const userId = query.get("userId");
  const elements = useElements();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  console.log("Client Secret:", clientSecret);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    // Check if Stripe.js, elements, and clientSecret are available
    if (!stripe || !elements || !clientSecret) {
      setIsSubmitting(false);
      console.log("Stripe.js has not fully loaded yet, or the client secret is missing.");
      return;
    }

    const paymentElement = elements.getElement(PaymentElement);

    // Confirm the payment with Stripe
    try {
      setIsLoading(true);
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        redirect: "if_required",
      });

      if (error) {
        console.log("Error in payment:", error.message);
        setIsLoading(false);
        setError(error.message);
      } else if (paymentIntent) {
        console.log("PaymentIntent:", paymentIntent);
        if (paymentIntent.status === "succeeded") {
          console.log("Payment successful. Notifying the server...");
          console.log("clientSecret:", clientSecret);

          // Notify the server that the payment has been confirmed
          fetch(`api/auth/confirm-payment/${userId}`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              payment_intent_id: paymentIntent.id, // Make sure this matches the key expected by your backend
            }),
          })
            .then((response) => response.json())
            .then((data) => {
              console.log("Payment confirmation response from server:", data);
              setIsLoading(false);
              navigate("/login")
            })
            .catch((error) => {
              console.error("Error notifying the server about payment success:", error);
              setIsLoading(false);
              setError(error.message);
            });
        } else if (paymentIntent.status === "requires_action") {
          console.log("Payment requires further action. Attempting to resolve...");
          setIsLoading(false);
          // Confirm the payment with additional action
          const { error: confirmationError } = await stripe.confirmCardPayment(clientSecret);
          if (confirmationError) {
            console.log("The payment failed after action:", confirmationError.message);
            setIsLoading(false);
            setError(confirmationError.message);
          } else {
            console.log("Payment successful after additional action.");
            setIsLoading(false);
            navigate("/login")
          }
        } else {
          console.log("Payment failed with status:", paymentIntent.status);
          setError("Payment failed. Please try again.");
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.error("Error confirming the payment:", error);
      setError(error.message);
      setIsLoading(false);
    }
    setIsSubmitting(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <button type="submit" disabled={!stripe || !elements || !clientSecret || isSubmitting}>
        Pay
      </button>
      {isLoading && <div className="overlay"></div>}
      {isLoading && <div className="spin"></div>}
      {error && <div className="error"><p>{error}</p></div>}
    </form>
  );
}

export default PaymentForm;
