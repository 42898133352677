import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import LeftNavBar from "../components/LeftNavbar";

function AddNewChild({}) {
  const [firstName, setFirstName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [errors, setErrors] = useState({});
  const [gender, setGender] = useState("");
  const [hasMedical, setHasMedical] = useState(true);
  const [medical, setMedical] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const monthInputRef = useRef(null);
  const yearInputRef = useRef(null);
  const sessionId = localStorage.getItem("sessionId");
  const [sameAddress, setSameAddress] = useState(true);
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [postcode, setPostcode] = useState("");
  const navigate = useNavigate();

  const validateForm = () => {
    let isValid = true;
    let errors = {};

    if (!firstName) {
      isValid = false;
      errors.firstName = "First name is required";
    }
    if (!surname) {
      isValid = false;
      errors.surname = "Surname is required";
    }
    if (!day || !month || !year) {
      isValid = false;
      errors.dob = "Complete date of birth is required";
    }

    if (year < 2005 || year > 2024) {
      isValid = false;
      errors.dob = "Please enter a valid year of birth between 2005 and 2024";
    }
    if (!gender) {
        isValid = false;
        errors.gender = 'Please select a gender';
    }

    if (sameAddress === true) {
      if (!address1) {
        isValid = false;
        errors.address1 = "Address Line 1 is required";
      }
      if (!address2) {
        isValid = false;
        errors.address2 = "Address Line 2 is required";
      }
      if (!city) {
        isValid = false;
        errors.city = "Town/City is required";
      }
      if (!postcode) {
        isValid = false;
        errors.postcode = "Postcode is required";
      }
      setErrors(errors);
      return isValid;
    }
    setErrors(errors);
    return isValid;
  };

  const handleSameAddress = () => {
    setSameAddress(!sameAddress);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) {
      return; // Stop submission if validation fails
    }

    setIsLoading(true); // Show loader when submitting

    const dob = `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    console.log({ day, month, year, dob });

    // Simulate async operation with timeout
    await new Promise((resolve) => setTimeout(resolve, 2000));

    const response = await fetch("api/auth/add-new-child", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        sessionId,
        firstName,
        surname,
        email,
        phone,
        dob,
        address1,
        address2,
        city,
        postcode,
        sameAddress,
        gender,
        medical,
      }),
    }).then((response) => {
      if (response.status === 201) {
        setIsLoading(false);
        navigate("/childaccountsettings");
      }
      if (response.status === 401) {
        navigate("/login");
        setIsLoading(false);
      }
      if (response.status === 409) {
        setIsLoading(false);
        errors.email = "Email already exists";
      }
      if (!response.ok) {
      }
      setIsLoading(false);
      setErrors(errors);
      return response.json();
    });

    setIsLoading(false);
  };

  const handleDayChange = (e) => {
    const value = Math.max(0, Math.min(31, Number(e.target.value))); // Adjusted max day to 31
    if (e.target.value.length === 2) {
      monthInputRef.current.focus();
    }
    setDay(value ? value.toString() : "");
  };

  const handleMonthChange = (e) => {
    const value = Math.max(0, Math.min(12, Number(e.target.value))); // Adjusted min month to 1
    if (e.target.value.length === 2) {
      yearInputRef.current.focus();
    }
    setMonth(value ? value.toString() : "");
  };

  const handleYearChange = (e) => {
    const value = Math.max(0, Math.min(2024, Number(e.target.value))); // Adjusted min year to 1900 for realistic age range
    setYear(value ? value.toString() : "");
  };

  return (
    <>
      <LeftNavBar />
      <h1>Add New Child</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <input
            type="text"
            id="firstName"
            value={firstName}
            placeholder="First Name"
            onChange={(e) => setFirstName(e.target.value)}
          />
          {errors.firstName && (
            <div style={{ color: "red" }}>{errors.firstName}</div>
          )}
        </div>
        <div>
          <input
            type="text"
            id="surname"
            placeholder="Surname"
            value={surname}
            onChange={(e) => setSurname(e.target.value)}
          />
          {errors.surname && (
            <div style={{ color: "red" }}>{errors.surname}</div>
          )}
        </div>
        <div className="dob">
          <input
            type="text"
            id="day"
            placeholder="dd"
            value={day}
            onChange={handleDayChange}
          />
          <input
            type="text"
            id="month"
            placeholder="mm"
            value={month}
            onChange={handleMonthChange}
            ref={monthInputRef}
          />
          <input
            type="text"
            id="year"
            placeholder="yyyy"
            value={year}
            onChange={handleYearChange}
            ref={yearInputRef}
          />
        </div>
        {errors.dob && <div style={{ color: "red" }}>{errors.dob}</div>}
        <div>
          <label style={{ fontSize: "18px" }} htmlFor="gender">
            Gender
          </label>
          <select
            id="gender"
            name="gender"
            value={gender}
            onChange={(e) => setGender(e.target.value)}
          >
            <option value="" disabled>
              Select
            </option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
          {errors.gender && <div style={{ color: "red" }}>{errors.gender}</div>}
          <p style={{ fontSize: "12px" }}>
            (this will be used for event categories)
          </p>
        </div>
        <div>
          <h2>Medical Conditions</h2>
          <label style={{ fontSize: "18px" }} htmlFor="none">
            None
          </label>
          <input
            style={{ margin: "10px" }}
            className="checkbox"
            type="checkbox"
            id="none"
            name="none"
            value="none"
            onChange={() => setHasMedical(!hasMedical)}
          />
          {hasMedical && (
            <input
              type="text"
              id="medical"
              placeholder="Medical Conditions"
              value={medical}
              onChange={(e) => setMedical(e.target.value)}
            />
          )}
        </div>
        <div>
          <input
            type="email"
            id="email"
            placeholder="Child Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <p className="optional-text">(optional)</p>
          {errors.email && <div style={{ color: "red" }}>{errors.email}</div>}
        </div>
        <div>
          <input
            type="tel"
            id="phone"
            placeholder="Child Phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <p className="optional-text">(optional)</p>
        </div>
        <div>
          <input
            type="checkbox"
            id="same-address"
            style={{ marginRight: "10px" }}
            onClick={handleSameAddress}
          />
          <label htmlFor="same-address">Same address as me</label>
        </div>

        {sameAddress && (
          <div>
            <input
              type="text"
              id="address1"
              placeholder="Address Line 1"
              value={address1}
              onChange={(e) => setAddress1(e.target.value)}
            />
            {errors.address1 && (
              <div style={{ color: "red" }}>{errors.address1}</div>
            )}
          </div>
        )}
        {sameAddress && (
          <div>
            <input
              type="text"
              id="address2"
              placeholder="Address Line 2"
              value={address2}
              onChange={(e) => setAddress2(e.target.value)}
            />
            {errors.address2 && (
              <div style={{ color: "red" }}>{errors.address2}</div>
            )}
          </div>
        )}
        {sameAddress && (
          <div>
            <input
              type="text"
              id="city"
              placeholder="Town/City"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
            {errors.city && <div style={{ color: "red" }}>{errors.city}</div>}
          </div>
        )}
        {sameAddress && (
          <div>
            <input
              type="text"
              id="postcode"
              placeholder="Postcode"
              value={postcode}
              onChange={(e) => setPostcode(e.target.value)}
            />
            {errors.postcode && (
              <div style={{ color: "red" }}>{errors.postcode}</div>
            )}
          </div>
        )}

        <button type="submit">{isLoading ? "Loading..." : "Next"}</button>
        {errors.message && <div style={{ color: "red" }}>{errors.message}</div>}
        {isLoading && <div className="overlay"></div>}
        {isLoading && <div className="spin"></div>}
      </form>
    </>
  );
}

export default AddNewChild;
