import React, { useEffect, useState } from "react";
import LeftNavBar from "../components/LeftNavbar";
import NavBar from "../components/NavBar";
import { error } from "jquery";

function ChildAccountMannage() {
  const [isLoading, setIsLoading] = useState(false);
  const [hasEmail, setHasEmail] = useState(false);
  const [hasPhone, setHasPhone] = useState(false);
  const [moreInfo, setMoreInfo] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState([
    {
      convertEmail: "",
      convertPhone: "",
      convertPassword: "",
    },
  ]);
  const [errors, setErrors] = useState("");

  const validationForForm = (index) => {
    let isValid = true;
    let errors = {};

    if (!childAccounts[index].email) {
      isValid = false;
      errors.convertEmail = "Email is required";
    }

    if (!childAccounts[index].phone) {
      isValid = false;
      errors.convertPhone = "Phone number is required";
    }

    if (!childAccounts[index].addressLine1) {
      isValid = false;
      errors.addressLine1 = "Address Line 1 is required";
    }

    if (!childAccounts[index].addressLine2) {
      isValid = false;
      errors.addressLine2 = "Address Line 2 is required";
    }

    if (!childAccounts[index].townCity) {
      isValid = false;
      errors.townCity = "Town/City is required";
    }

    if (!childAccounts[index].postcode) {
      isValid = false;
      errors.postcode = "Postcode is required";
    }

    if (!childAccounts[index].dob) {
      isValid = false;
      errors.dob = "Date of birth is required";
    }

    if (childAccounts[index].year < 2005 || childAccounts[index].year > 2024) {
      isValid = false;
      errors.dob = "Please enter a valid year of birth between 2005 and 2024";
    }

    setError(errors);
    return isValid;
  };

  const [convertOverlay, setConvertOverlay] = useState(false);
  const [details, setDetails] = useState({
    id: "",
    email: "",
    firstName: "",
    surname: "",
    phone: "",
    dob: "",
    addressLine1: "",
    addressLine2: "",
    townCity: "",
    postcode: "",
    convertEmail: "",
    convertPhone: "",
    hasEmail: true,
    hasPhone: true,
    convertPassword: "",
  });
  const [childAccounts, setChildAccounts] = useState([]);

  const handleChange = (event, name, index) => {
    setChildAccounts(
      childAccounts.map((account, i) => {
        if (i !== index) {
          // This isn't the item we care about - keep it as-is
          return account;
        }

        // Otherwise, this is the one we want - return an updated value
        let newDetails = { ...account };
        if (name === "dob") {
          let dobParts = newDetails.dob.split("-");
          if (event.target.name === "day") dobParts[0] = event.target.value;
          if (event.target.name === "month") dobParts[1] = event.target.value;
          if (event.target.name === "year") dobParts[2] = event.target.value;
          newDetails.dob = dobParts.join("-");
        } else {
          newDetails[event.target.name] = event.target.value;
        }
        return newDetails;
      })
    );
  };

  const handleMoreInfoClick = (index) => {
    setChildAccounts(
      childAccounts.map((details, i) =>
        i === index ? { ...details, moreInfo: !details.moreInfo } : details
      )
    );
  };

  const handleEditClick = (index) => {
    setChildAccounts(
      childAccounts.map((details, i) =>
        i === index ? { ...details, isEditing: !details.isEditing } : details
      )
    );
  };

  const handleSaveClick = (index) => {
    setIsLoading(true);
    const sessionId = localStorage.getItem("sessionId");
    fetch("api/auth/save-child-info", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ sessionId, details: childAccounts[index] }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        setChildAccounts(
          childAccounts.map((account, i) =>
            i === index ? { ...account, isEditing: false } : account
          )
        );
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const response = await fetch("api/auth/load-child-account-page", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ sessionId: localStorage.getItem("sessionId") }),
      })
        .then((response) => response.json())
        .then((data) => {
          const childAccounts = data.childLinks.map((account) => ({
            ...account,
            moreInfo: false,
            isEditing: false,
            convertOverlay: false,
            verifyOverlay: false,
            hasEmail: account.email ? true : false,
            hasPhone: account.phone ? true : false,
            email: account.email,
            phone: account.phone,
            addressLine1: account.addressLine1,
            addressLine2: account.addressLine2,
            townCity: account.townCity,
            postcode: account.postcode,
            dob: account.dob,
          }));
          console.log(data);
          setIsLoading(false);
          setChildAccounts(childAccounts);
        })
        .catch((error) => {
          console.error("Error:", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    })();
  }, []);

  const validateForm = (index) => {
    let isValid = true;
    let errors = {
      ...error,
      convertEmail: "",
      convertPhone: "",
      convertPassword: "",
    };

    if (!childAccounts[index].hasEmail) {
      if (!childAccounts[index].convertEmail) {
        isValid = false;
        errors.convertEmail = "Email is required";
      }
    }

    if (!childAccounts[index].hasPhone) {
      if (!childAccounts[index].convertPhone) {
        isValid = false;
        errors.convertPhone = "Phone number is required";
      }
    }

    if (!childAccounts[index].convertPassword) {
      isValid = false;
      errors.convertPassword = "Password is required";
    }

    setError(errors);
    return isValid;
  };

  const convertAccount = async (index) => {
    setIsLoading(true);
    const account = childAccounts[index];
    if (!validateForm(index)) {
      setIsLoading(false);
      return;
    }

    try {
      const response = await fetch("api/auth/convert-to-account", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          sessionId: localStorage.getItem("sessionId"),
          childId: account.id,
          email: account.convertEmail,
          phone: account.convertPhone,
          password: account.convertPassword,
        }),
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      } else {
      }
      const data = await response.json();
      console.log(data);
      setChildAccounts(
        childAccounts.map((account, i) =>
          i === index
            ? { ...account, convertOverlay: false, verifyOverlay: true }
            : account
        )
      );
    } catch (error) {
      console.error("Error:", error);
      if (error.message === "CONFLICT") {
        setErrors("Email already exists");
      } else {
        setErrors("An error occurred. Please try again later");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleUnlink = (index) => {
    setIsLoading(true);
    fetch("api/auth/unlink-child", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        sessionId: localStorage.getItem("sessionId"),
        childId: childAccounts[index].id,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        setChildAccounts(childAccounts.filter((account, i) => i !== index));
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const verifyAccount = async (index) => {
    setIsLoading(true);
    const account = childAccounts[index];
    try {
      const response = await fetch(`api/auth/verify/${account.id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          code: account.verifyCode,
        }),
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }
      const data = await response.json();
      console.log(data);
      setChildAccounts(
        childAccounts.map((account, i) =>
          i === index ? { ...account, verifyOverlay: false } : account
        )
      );
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleConvert = (index) => {
    setChildAccounts(
      childAccounts.map((account, i) =>
        i === index
          ? { ...account, convertOverlay: !account.convertOverlay }
          : account
      )
    );
  };

  return (
    <div>
      <div>
        {isLoading && <div className="overlay"></div>}
        {isLoading && <div className="spin"></div>}
      </div>
      <LeftNavBar />
      <div>
        <h1>Child Account Manage</h1>
        <p style={{ maxWidth: "500px" }}>
          Linked child accounts can be upgraded to full accounts when children
          reach 13 years of age. To do this please add their email and phone
          number (they must be unique to them not your contact details).{" "}
        </p>
        <p style={{ maxWidth: "500px" }}>
          Creating full accounts allows 13-18 Year olds to control their own
          membership and book events themselves. They will remain free to join
          and linked to your account until they turn 18.
        </p>
        <div>
          <h2>Linked accounts</h2>
          <div className="linked-accounts">
            {childAccounts.map((details, index) => (
              <ul key={index}>
                <p>
                  First Name:{" "}
                  {details.isEditing ? (
                    <>
                      <br />
                      <input
                        type="text"
                        name="firstName"
                        value={details.firstName}
                        onChange={(event) =>
                          handleChange(event, "firstName", index)
                        }
                      />
                    </>
                  ) : (
                    <span>{details.firstName}</span>
                  )}
                </p>
                <p>
                  Surname:{" "}
                  {details.isEditing ? (
                    <>
                      <br />
                      <input
                        type="text"
                        name="surname"
                        value={details.surname}
                        onChange={(event) =>
                          handleChange(event, "surname", index)
                        }
                      />
                    </>
                  ) : (
                    <span>{details.surname}</span>
                  )}
                </p>
                <p>
                  Date of birth:
                  {details.isEditing ? (
                    <>
                      <br />
                      <div>
                        <input
                          type="text"
                          name="day"
                          value={details.dob.split("-")[0]}
                          onChange={(e) => handleChange(e, "dob", index)}
                          placeholder="DD"
                          maxLength="2"
                          size="2"
                        />
                        <input
                          type="text"
                          name="month"
                          value={details.dob.split("-")[1]}
                          onChange={(e) => handleChange(e, "dob", index)}
                          placeholder="MM"
                          maxLength="2"
                          size="2"
                        />
                        <input
                          type="text"
                          name="year"
                          value={details.dob.split("-")[2]}
                          onChange={(e) => handleChange(e, "dob", index)}
                          placeholder="YYYY"
                          maxLength="4"
                          size="4"
                        />
                      </div>
                    </>
                  ) : (
                    <span> {details.dob}</span>
                  )}
                </p>
                <div className={details.moreInfo ? "slide-down" : ""}>
                  {details.moreInfo && (
                    <>
                      <p>
                        {details.hasEmail ? "Email: " : "No email linked"}
                        {details.isEditing ? (
                          <>
                            <br />
                            <input
                              type="text"
                              name="email"
                              placeholder="Email"
                              value={details.email}
                              onChange={(e) => handleChange(e, "email", index)}
                            />
                          </>
                        ) : (
                          <span>{details.email}</span>
                        )}
                      </p>

                      <p>
                        {details.hasPhone
                          ? "Phone: "
                          : details.isEditing
                          ? "Phone"
                          : "No phone number linked"}
                        {details.isEditing ? (
                          <>
                            <br />
                            <input
                              type="text"
                              name="phone"
                              placeholder="Phone"
                              defaultValue={details.phone}
                              onChange={(e) => handleChange(e, "phone", index)}
                            />
                          </>
                        ) : (
                          <span>{details.phone}</span>
                        )}
                      </p>
                      <div>
                        <p>
                          Gender :{" "}
                          {details.isEditing ? (
                            <>
                              <br />
                              <select
                                id="gender"
                                name="gender"
                                defaultValue={details.gender}
                                onChange={(e) =>
                                  handleChange(e, "gender", index)
                                }
                              >
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                              </select>
                            </>
                          ) : (
                            <span>{details.gender}</span>
                          )}
                        </p>
                        <p>
                          Address Line 1:{" "}
                          {details.isEditing ? (
                            <>
                              <br />
                              <input
                                type="text"
                                name="addressLine1"
                                value={details.addressLine1}
                                onChange={(e) =>
                                  handleChange(e, "addressLine1", index)
                                }
                              />
                            </>
                          ) : (
                            <span>{details.addressLine1}</span>
                          )}
                        </p>
                        <p>
                          Address Line 2:{" "}
                          {details.isEditing ? (
                            <>
                              <br />
                              <input
                                type="text"
                                name="addressLine2"
                                value={details.addressLine2}
                                onChange={(e) =>
                                  handleChange(e, "addressLine2", index)
                                }
                              />
                            </>
                          ) : (
                            <span>{details.addressLine2}</span>
                          )}
                        </p>
                        <p>
                          Town/City:{" "}
                          {details.isEditing ? (
                            <>
                              <br />
                              <input
                                type="text"
                                name="townCity"
                                value={details.townCity}
                                onChange={(e) =>
                                  handleChange(e, "townCity", index)
                                }
                              />
                            </>
                          ) : (
                            <span>{details.townCity}</span>
                          )}
                        </p>
                        <p>
                          Postcode:{" "}
                          {details.isEditing ? (
                            <>
                              <br />
                              <input
                                type="text"
                                name="postcode"
                                value={details.postcode}
                                onChange={(e) =>
                                  handleChange(e, "postcode", index)
                                }
                              />
                            </>
                          ) : (
                            <span>{details.postcode}</span>
                          )}
                        </p>{" "}
                        <p>Account ID: {details.id}</p>
                      </div>
                    </>
                  )}

                  <button onClick={() => handleMoreInfoClick(index)}>
                    {details.moreInfo
                      ? "Hide Details \u25BC"
                      : "View Details \u25B2"}
                  </button>

                  {details.moreInfo && (
                    <div>
                      <button
                        id="editDetailsButton"
                        onClick={() =>
                          details.isEditing
                            ? handleSaveClick(index)
                            : handleEditClick(index)
                        }
                      >
                        {details.isEditing ? "Save Details" : "Edit Details"}
                      </button>
                      <button onClick={() => handleConvert(index)}>
                        Convert to Account
                      </button>
                      <button onClick={() => handleUnlink(index)}>
                        Unlink Account
                      </button>
                    </div>
                  )}
                </div>
                {details.convertOverlay && (
                  <div className="overlay-2">
                    <form className="convert-overlay" autocomplete="nope">
                      <h3>Convert to account</h3>
                      {!details.hasEmail && (
                        <input
                          type="email"
                          name="convertEmail"
                          onChange={(event) =>
                            handleChange(event, "convertEmail", index)
                          }
                          value={details.convertEmail}
                          placeholder="Email"
                          autocomplete="nope"
                        />
                      )}
                      {error.convertEmail && (
                        <p className="error">{error.convertEmail}</p>
                      )}
                      {!details.hasPhone && (
                        <input
                          type="tel"
                          name="convertPhone"
                          onChange={(event) =>
                            handleChange(event, "convertPhone", index)
                          }
                          value={details.convertPhone}
                          placeholder="Phone"
                          autocomplete="nope"
                        />
                      )}
                      {error.convertPhone && (
                        <p className="error">{error.convertPhone}</p>
                      )}
                      <input
                        type="password"
                        name="convertPassword"
                        onChange={(event) =>
                          handleChange(event, "convertPassword", index)
                        }
                        value={details.convertPassword}
                        placeholder="Password"
                        autocomplete="new-password"
                      />
                      {error.convertPassword && (
                        <p className="error">{error.convertPassword}</p>
                      )}
                      <br />
                      <button
                        type="button"
                        onClick={() => convertAccount(index)}
                      >
                        Convert
                      </button>
                      {errors && <p className="error">{errors}</p>}
                    </form>
                  </div>
                )}
                {details.verifyOverlay && (
                  <div className="overlay-2">
                    <form className="verify-overlay convert-overlay">
                      <h3>Verify Account</h3>
                      <input
                        type="text"
                        name="verifyCode"
                        onChange={(event) =>
                          handleChange(event, "verifyCode", index)
                        }
                        value={details.verifyCode}
                        placeholder="Verification Code"
                      />
                      <br />
                      <button
                        type="button"
                        onClick={() => verifyAccount(index)}
                      >
                        Verify
                      </button>
                      {error.message && (
                        <p className="error">{error.message}</p>
                      )}
                    </form>
                  </div>
                )}
              </ul>
            ))}
          </div>
          <a href="/add-new-child">
            <button style={{ marginTop: "10px" }}>Link new Account</button>
          </a>
        </div>
      </div>
      <NavBar />
    </div>
  );
}

export default ChildAccountMannage;
