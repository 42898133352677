import React, { useState } from 'react';

function LoginForm({ onLogin, error }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const isValid = validateInput(email, password);
        if (!isValid) {
            return;
        }
        await onLogin(email, password);
    };

    // Basic client-side validation
    const validateInput = (email, password) => {
        return email.length > 0 && password.length > 0; 
    };

    return (
        <form onSubmit={handleSubmit}>
            <div>
                <input placeholder='Email' className='email' type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div>
                <input placeholder='Password' type="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)}/>
            </div>
            <a href='/password-reset'>Forgot password?</a>
    
            {error && <div style={{ color: 'red' }}>{error}</div>}
            <button type="submit">{isLoading ? 'Loading...' : 'login'}</button>
             {isLoading && <div className="overlay"></div>} 
            {isLoading && <div className="spin"></div>} 
            <a href="/register" >Aren't a member Register</a>
        </form>
    );
}

export default LoginForm;