import React, { useEffect, useState } from "react";
import NavBar from "../components/NavBar";
import { useNavigate } from "react-router-dom";

function ManageMember() {
    const sessionId = localStorage.getItem("sessionId");
    const [isEditing, setIsEditing] = useState(false);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [details, setDetails] = useState({
        id: "",
        email: "",
        firstName: "",
        surname: "",
        phone: "",
        dob: "",
        addressLine1: "",
        addressLine2: "",
        townCity: "",
        postcode: "",
        members: [],
    });

    useEffect(() => {
        setIsLoading(true);
        const fetchData = async () => {
            await fetch("api/auth/load-members", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ sessionId }),
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.error) {
                        console.log(details.error);
                        setIsLoading(false);
                    } else {
                        console.log(data);
                        setDetails(prevDetails => ({ ...prevDetails, members: data.members }));
                        setIsLoading(false);
                    }
                })
        };

        fetchData();
    }, [details.error, sessionId]);

    const handleEditClick = (index) => {
        setIsEditing((prevIsEditing) => {
            const updatedMembers = [...details.members];
            updatedMembers[index].isEditing = !prevIsEditing;
            setDetails((prevDetails) => ({
                ...prevDetails,
                members: updatedMembers,
            }));
            return !prevIsEditing;
        });
    };

    const handleSaveClick = (index) => {
        setIsLoading(true);
        const sessionId = localStorage.getItem("sessionId");
        const memberDetails = details.members[index]; // get the member details at the given index
        fetch("api/auth/update-member-details", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ sessionId, memberDetails }), // send the member details
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then((data) => {
                console.log(data);
                setIsEditing(false);
            })
            .catch((error) => {
                console.error("Error:", error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };


    const handleChange = (event, index, name) => {
        setDetails((prevDetails) => {
            let newDetails = { ...prevDetails };
            let newMembers = [...newDetails.members];
            if (name === "dob") {
                let dobParts = newMembers[index].dob.split("-");
                if (event.target.name === "day") dobParts[0] = event.target.value;
                if (event.target.name === "month") dobParts[1] = event.target.value;
                if (event.target.name === "year") dobParts[2] = event.target.value;
                newMembers[index].dob = dobParts.join("-");
            } else {
                newMembers[index][event.target.name] = event.target.value;
            }
            newDetails.members = newMembers;
            return newDetails;
        });
    };

    const handleLoginToUser = (index) => {
        let memberId = details.members[index].id
        setIsLoading(true);
        console.log(memberId);
        fetch("api/auth/login-to-user", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ sessionId, memberId }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.error) {

                    setIsLoading(false);
                } else {
                    console.log(data);
                    setIsLoading(false);
                    localStorage.setItem("sessionId", data.sessionId);
                    navigate("/dashboard")
                }
            })
    }


    const handleShowDetails = (index) => {
        let memberId = details.members[index].id
        setIsLoading(true);
        console.log(memberId);
        fetch("api/auth/load-member-details", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ sessionId, memberId }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.error) {
                    console.log(details.error);
                    setIsLoading(false);
                } else {
                    console.log(data);
                    setIsLoading(false);
                    setDetails(prevDetails => {
                        let newMembers = [...prevDetails.members];
                        newMembers[index] = { ...data.memberDetails, showDetails: !data.memberDetails.showDetails };
                        return { ...prevDetails, members: newMembers };
                    });
                }
            })
    }

    const handleHideDetails = (index) => {
        setDetails((prevDetails) => {
            let newDetails = { ...prevDetails };
            newDetails.members[index].showDetails = false;
            newDetails.members[index].isEditing = false;
            return newDetails;
        });
    }


    return (
        <div>
            <h1 align="center" >Manage Members</h1>
            <div className="member-info-box" >
                {details.members.map((member, index) => (
                    <div className="box" key={member.id}>
                        {member.showDetails || <h2>{member.firstName} {member.surname}</h2>}
                        <p>
                            Member ID: <br /> {member.id}
                        </p>
                        <p>
                            {member.isEditing && <>Email:{" "}</>}
                            {member.isEditing ? (
                                <input type="text" name="email" value={member.email} onChange={(e) => handleChange(e, index)} />
                            ) : (
                                <span>{member.email}</span>
                            )}
                        </p>

                        {member.showDetails && <div>
                            <p>
                                First Name:{" "} <br />
                                {member.isEditing ? (
                                    <input type="text" name="firstName" value={member.firstName} onChange={(e) => handleChange(e, index)} />
                                ) : (
                                    <span>{member.firstName}</span>
                                )}
                            </p>
                            <p>
                                Surname:{" "} <br />
                                {member.isEditing ? (
                                    <input type="text" name="surname" value={member.surname} onChange={(e) => handleChange(e, index)} />
                                ) : (
                                    <span>{member.surname}</span>
                                )}
                            </p>
                            <p>
                                Phone:{" "} <br />
                                {member.isEditing ? (
                                    <input type="text" name="phone" value={member.phone} onChange={(e) => handleChange(e, index)} />
                                ) : (
                                    <span>{member.phone}</span>
                                )}
                            </p>
                            <p>
                                Date of birth: <br />
                                {member.isEditing ? (
                                    <div>
                                        <input
                                            type="text"
                                            name="day"
                                            value={member.dob.split("-")[0]}
                                            onChange={(e) => handleChange(e, index)}
                                            placeholder="DD"
                                            maxLength="2"
                                            size="2"
                                        />
                                        <input
                                            type="text"
                                            name="month"
                                            value={member.dob.split("-")[1]}
                                            onChange={(e) => handleChange(e, index)}
                                            placeholder="MM"
                                            maxLength="2"
                                            size="2"
                                        />
                                        <input
                                            type="text"
                                            name="year"
                                            value={member.dob.split("-")[2]}
                                            onChange={(e) => handleChange(e, index)}
                                            placeholder="YYYY"
                                            maxLength="4"
                                            size="4"
                                        />
                                    </div>
                                ) : (
                                    <span>{member.dob}</span>
                                )}
                            </p>
                            <p>
                                Address Line 1:{" "} <br />
                                {member.isEditing ? (
                                    <input
                                        type="text"
                                        name="addressLine1"
                                        value={member.addressLine1}
                                        onChange={(e) => handleChange(e, index)}
                                    />
                                ) : (
                                    <span>{member.addressLine1}</span>
                                )}
                            </p>
                            <p>
                                Address Line 2:{" "} <br />
                                {member.isEditing ? (
                                    <input
                                        type="text"
                                        name="addressLine2"
                                        value={member.addressLine2}
                                        onChange={(e) => handleChange(e, index)}
                                    />
                                ) : (
                                    <span>{member.addressLine2}</span>
                                )}
                            </p>
                            <p>
                                Town/City:{" "} <br />
                                {member.isEditing ? (
                                    <input type="text" name="townCity" value={member.townCity} onChange={(e) => handleChange(e, index)} />
                                ) : (
                                    <span>{member.townCity}</span>
                                )}
                            </p>
                            <p>
                                Postcode:{" "} <br />
                                {member.isEditing ? (
                                    <input type="text" name="postcode" value={member.postcode} onChange={(e) => handleChange(e, index)} />
                                ) : (
                                    <span>{member.postcode}</span>
                                )}
                            </p>
                            <p>
                                Emergancy Contact:{" "} <br />
                                {member.isEditing ? (
                                    <input type="text" name="emergancyContact" value={member.emergancyContactName} onChange={(e) => handleChange(e, index)} />
                                ) : (
                                    <span>{member.emergancyContactName}</span>
                                )}
                            </p>
                            <p>
                                Emergancy Contact Phone:{" "} <br />
                                {member.isEditing ? (
                                    <input type="text" name="emergancyContactNumber" value={member.emergancyContactNumber} onChange={(e) => handleChange(e, index)} />
                                ) : (
                                    <span>{member.emergancyContactNumber}</span>
                                )}
                            </p>
                            <p>
                                Emergancy Contact Relationship:{" "} <br />
                                {member.isEditing ? (
                                    <input type="text" name="emergancyContactRelationship" value={member.emergancyContactRelationship} onChange={(e) => handleChange(e, index)} />
                                ) : (
                                    <span>{member.emergancyContactRelationship}</span>
                                )}
                            </p>
                            <p>
                                Medical Conditions:{" "} <br />
                                {member.isEditing ? (
                                    <input type="text" name="medicalConditions" value={member.medicalConditions} onChange={(e) => handleChange(e, index)} />
                                ) : (
                                    <span>{member.medicalConditions}</span>
                                )}
                            </p>
                            <p>
                                {member.isChild === true && <> 
                                    Child linked to:{" "} <br />
                                    <span>{member.parentName}</span></>}
                            </p>
                            <p>Verified: {member.verified ? 'Yes' : 'No'}</p>
                            <p>Has Paid: {member.hasPaid ? 'Yes' : 'No'}</p>
                            <p>Is Child: {member.isChild ? 'Yes' : 'No'} </p>

                            <button id="editDetailsButton" onClick={() => member.isEditing ? handleSaveClick(index) : handleEditClick(index)}>
                                {member.isEditing ? "Save Details" : "Edit Details"}
                            </button>
                        </div>}
                        <button id="showDetailsButton" onClick={() => member.showDetails ? handleHideDetails(index) : handleShowDetails(index)}>
                            {member.showDetails ? "Hide Details" : "Show Details"}
                        </button>
                        <button onClick={() => handleLoginToUser(index)}>Login to user</button>
                    </div>
                ))}</div>
            <NavBar />
            {isLoading && <div className="overlay"></div>}
            {isLoading && <div className="spin"></div>}
        </div>
    );
}

export default ManageMember;